import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { Card } from './Card'
import PlansComparisonTable from '../components/PlansComparisonTable'
import SwitchBox from '@src/components/SwitchBox'

import useGetAccount from '@helpers/hooks/useGetAccount'
import { plansData, TeamSize } from '../constants'

import { Plan } from '@type/common'
import { RateType } from '@src/types/billing'
import styles from './index.module.css'

interface PlansProps {
  onStartPlanClick: React.MouseEventHandler<HTMLButtonElement>
  setIsPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const Switcher: FC<{ selected: string; onChange: (option: TeamSize) => void }> = ({ selected, onChange }) => {
  const { t } = useTranslation('billing')

  return (
    <div className="flex items-center justify-center">
      <button
        className={cx(
          styles.button,
          selected === TeamSize.small ? styles.buttonActive : cx(styles.buttonInactive, '-mr-1.5 rounded-l-md'),
        )}
        onClick={() => onChange(TeamSize.small)}
      >
        {t('forSmallTeams')}
      </button>
      <button
        className={cx(
          styles.button,
          selected === TeamSize.large ? styles.buttonActive : cx(styles.buttonInactive, '-ml-1.5 rounded-r-md'),
        )}
        onClick={() => onChange(TeamSize.large)}
      >
        {t('forLargeTeams')}
      </button>
    </div>
  )
}

export const Plans: FC<PlansProps> = ({ onStartPlanClick, setIsPopupVisible }) => {
  const { t } = useTranslation('billing')

  const account = useGetAccount()
  const isOnboardingAttended = account?.team?.is_onboarding_attended
  const [teamSize, setTeamSize] = useState(TeamSize.small)
  const [billingFrequency, setBillingFrequency] = useState(RateType.monthly)

  useEffect(() => {
    if (teamSize === TeamSize.large) {
      setBillingFrequency(RateType.annually)
    } else {
      setBillingFrequency(RateType.monthly)
    }
  }, [teamSize])

  return (
    <>
      <div className="px-1 xs:px-2 sm:px-3 md:px-5 lg:px-7 xl:px-8 2xl:px-16">
        <h2 className="text-3xl mx-4 sm:text-[30px] text-center leading-9 font-semibold sm:font-medium mb-4 sm:mb-8">
          {t('choosePlan')}
        </h2>
        <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-[1fr_auto_1fr] items-center gap-2 mb-8">
          <div className="lg:col-start-2 flex justify-center">
            <Switcher selected={teamSize} onChange={(option) => setTeamSize(option)} />
          </div>
          <div className="row-start-2 lg:row-start-1 lg:col-start-3 flex justify-center lg:justify-end px-2 items-center gap-2 gray-400 font-medium">
            <SwitchBox
              id="billing-frequency"
              label={t('monthly')}
              labelClassName={billingFrequency === RateType.monthly ? 'text-slate-950 font-semibold' : ''}
              disabled={teamSize === TeamSize.large}
              checked={billingFrequency === RateType.annually}
              onChange={() =>
                setBillingFrequency((prev) => (prev === RateType.monthly ? RateType.annually : RateType.monthly))
              }
            />
            <span className={billingFrequency === RateType.annually ? 'text-slate-950 font-semibold' : ''}>
              {t('annually')}
            </span>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'no-scrollbar px-1 xs:px-2 sm:px-3 md:px-5 lg:px-7 xl:px-8 2xl:px-16',
          'flex flex-col overflow-auto items-start xl:items-center',
        )}
      >
        <div className="flex pt-4 items-stretch flex-row overflow-auto gap-6 pb-8 px-4 justify-center w-min">
          {Object.entries(plansData)
            .filter(([, plan]) => plan?.show && plan?.teamSize === teamSize)
            .map(
              ([planKey, plan]) =>
                plan?.show && (
                  <Card
                    isOnboardingAttended={isOnboardingAttended}
                    setIsPopupVisible={setIsPopupVisible}
                    onStartPlanClick={onStartPlanClick}
                    key={planKey}
                    plan={planKey as Plan}
                    billingFrequency={billingFrequency}
                  />
                ),
            )}
        </div>
      </div>
      <PlansComparisonTable />
    </>
  )
}
