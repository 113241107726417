import React from 'react'
import { useTranslation } from 'react-i18next'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface AccountsPayableProps {}

const HERO_IMAGE = '/paywalls/accounts-payable/hero.png'
const ARTICLE_PO_IMAGE = '/paywalls/accounts-payable/po.png'
const ARTICLE_BILLS_IMAGE = '/paywalls/accounts-payable/bills.png'
const ARTICLE_COST_CENTER_IMAGE = '/paywalls/accounts-payable/cost-center.png'

export const AccountsPayable: React.FC<AccountsPayableProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'accountsPayable' })

  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section title={t('section.title')} text={t('section.text')} />
      <div className="flex flex-col gap-6">
        <Article title={t('articles.po.title')} image={ARTICLE_PO_IMAGE} text={t('articles.po.text')} />
        <Article title={t('articles.bills.title')} image={ARTICLE_BILLS_IMAGE} text={t('articles.bills.text')} />
        <Article
          title={t('articles.costCenter.title')}
          image={ARTICLE_COST_CENTER_IMAGE}
          text={t('articles.costCenter.text')}
          hasActionButtons
        />
      </div>
    </>
  )
}

export default AccountsPayable
