import { BudgetFrequency, DeductedSumType } from '@type/budget'

export const BudgetFrequencyOption: Partial<Record<BudgetFrequency, Option<BudgetFrequency>>> = {
  [BudgetFrequency.monthly]: {
    label: 'Monthly',
    value: BudgetFrequency.monthly,
  },
  [BudgetFrequency.quarterly]: {
    label: 'Quarterly',
    value: BudgetFrequency.quarterly,
  },
  [BudgetFrequency.yearly]: {
    label: 'Yearly',
    value: BudgetFrequency.yearly,
  },
}

export const BudgetFrequencyOptions: Option<BudgetFrequency>[] = Object.values(BudgetFrequencyOption)

export const DeductedSumTypeOption: Record<DeductedSumType, Option<DeductedSumType>> = {
  [DeductedSumType.gross]: {
    label: 'Gross',
    value: DeductedSumType.gross,
  },
  [DeductedSumType.net]: {
    label: 'Net',
    value: DeductedSumType.net,
  },
}

export const DeductedSumTypeOptions: Option<DeductedSumType>[] = Object.values(DeductedSumTypeOption)

export const StartDayOptions: Option<number>[] = Array.from(new Array(31)).map((_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}))

export class FilterYear {
  public static startYear = 2023

  public static get currentYear() {
    return new Date().getFullYear()
  }

  public static get yearOptionsCount() {
    return this.currentYear - this.startYear + 3
  }

  static options = [...Array(this.yearOptionsCount).keys()].map((_, i) => {
    const year = this.startYear + i
    return {
      label: year.toString(),
      value: year,
    }
  })
}
