import React, { FC, useRef, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'clsx'
import { generatePath, useNavigate } from 'react-router-dom'
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'

import Button from '@components/Button'
import { IconCopy, IconDotsVertical, IconTrash } from '@tabler/icons-react'
import { usePopupHandlers } from '@components/Popup'
import GenerateReportPopup from './GenerateReportPopup'

import styles from '../index.module.css'
import { Report } from '@models/Report'
import routes from '@src/Routes/routes'
import { useCreateReport, useDeleteReport } from '@queries/Reports'
import { requestErrorsHandler } from '@helpers/utils'
import { toast } from '@helpers/toaster'
import ButtonWithConfirmation from '@components/Button/ButtonWithConfirmation'

interface ReportCardProps {
  report: Report
}

const ReportCard: FC<ReportCardProps> = ({ report }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'card' })
  const { t: tCommon } = useTranslation('common')
  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const menuRef = useRef(null)
  const { isPopupOpen, setIsPopupOpen } = usePopupHandlers()
  const navigate = useNavigate()
  const { mutateAsync: deleteReport } = useDeleteReport()
  const { mutateAsync: createReport } = useCreateReport()

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, [])

  const handleGenerateReportClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsPopupOpen(true)
  }, [])

  const handleCardClick = useCallback(() => {
    const path = generatePath(routes.private.reportById, { id: report.id })
    navigate(path)
  }, [])

  const handleDeleteReport = useCallback(async () => {
    try {
      await deleteReport(report.id)
      toast.success(t('deletedToast', { name: report.name }))
    } catch (e) {
      requestErrorsHandler(e)
    }
  }, [report])

  const handleCopyReport = useCallback(async () => {
    try {
      const newReport = await createReport({ ...report, name: report.name + ' Copy', workflow: report.workflow?.id })
      navigate(generatePath(routes.private.reportById, { id: newReport.id }))
    } catch (e) {
      requestErrorsHandler(e)
    }
  }, [report])

  return (
    <div
      className="flex p-3 rounded-xl bg-white w-[20rem] justify-between gap-1 flex-shrink-0 cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="w-full overflow-y-hidden p-1">
        <div className={styles.reportCardLabel}>
          <span className={styles.reportCardTitle}>{t('nameLabel')}: </span>
          <span title={report.name}>{report.name}</span>
        </div>
        <div className={styles.reportCardLabel}>
          <span className={styles.reportCardTitle}>{t('workflowLabel')}: </span>
          <span title={report.workflow?.name}>{report.workflow?.name}</span>
        </div>

        <Button type="outlined" className="text-brand_primary mt-4" onClick={handleGenerateReportClick}>
          {t('generateButtonLabel')}
        </Button>
      </div>

      <div ref={menuRef} onClick={(e) => e.stopPropagation()}>
        <Button
          type="outlined"
          className={cx('p-0 border-0 shadow-none min-w-0 h-auto rounded-md', {
            'text-brand_primary bg-blue-50': menuState?.state === 'open',
          })}
          onClick={() => toggleMenu()}
        >
          <IconDotsVertical className="h-5 w-5" />
        </Button>

        <ControlledMenu
          menuClassName={styles.reportCardMenu}
          {...menuState}
          anchorRef={menuRef}
          onClose={() => toggleMenu()}
        >
          <MenuItem className={styles.reportCardMenuItem} onClick={handleCopyReport}>
            <IconCopy className="w-4 h-4" />
            <span>{tCommon('copy')}</span>
          </MenuItem>
          <MenuItem className="p-0">
            <ButtonWithConfirmation
              color="white"
              text={t('popupDelete.title')}
              subText={<Trans components={{ b: <strong /> }}>{t('popupDelete.text', { report: report.name })}</Trans>}
              confirmType="common"
              onClick={handleDeleteReport}
              className="flex gap-1 px-4 border-none shadow-none w-full h-7 justify-start hover:!bg-gray-200 font-normal transition-none"
            >
              <IconTrash className="w-4 h-4" />
              <span>{tCommon('delete')}</span>
            </ButtonWithConfirmation>
          </MenuItem>
        </ControlledMenu>
      </div>

      {isPopupOpen && <GenerateReportPopup close={handleClose} report={report} />}
    </div>
  )
}

export default ReportCard
