import React, { FC, useCallback, useState } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

import Button from '@components/Button'
import { IconArrowRight } from '@tabler/icons-react'
import { Input } from '@components/Form'

import styles from '../index.module.css'

import { KeyboardKeys } from '@type/common'

interface AiChatInputProps {
  sendPrompt(prompt: string): void

  isLoading?: boolean
  classes?: {
    container?: string
    control?: string
  }
  disabled?: boolean
}

const AiChatInput: FC<AiChatInputProps> = ({ sendPrompt, isLoading, classes, disabled = false }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'ai' })
  const [prompt, setPrompt] = useState('')

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
    setPrompt(e.target?.value)
  }, [])

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (KeyboardKeys.ENTER === (e.key as KeyboardKeys) && !e.shiftKey) {
      e.preventDefault()
      return handleSendPrompt()
    }
  }

  const handleSendPrompt = useCallback(() => {
    if (isLoading || !prompt) {
      return
    }
    setPrompt('')
    sendPrompt(prompt)
  }, [sendPrompt, prompt, isLoading])

  return (
    <div
      className={twMerge(cx('sticky -bottom-4 md:mt-10 pb-10 bg-white pt-2.5 flex justify-center', classes?.container))}
    >
      <div className={twMerge(cx(styles.input, classes?.control, disabled && 'hover:shadow-none'))}>
        <Input
          classes={{
            control: twMerge(cx('border-none !shadow-none h-7 hover:shadow-none text-lg ', disabled && '!bg-white')),
            container: 'w-full',
          }}
          className="grow !mb-0 flex items-center w-full"
          type="textarea"
          maxRows={15}
          placeholder={t('inputPlaceholder')}
          maxlength={10000}
          autoSize
          value={prompt}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        <Button
          className="h-[1.25rem] w-10 min-w-0 rounded-full disabled:!bg-gray-400 mb-1"
          disabled={!prompt || isLoading}
          onClick={handleSendPrompt}
        >
          <IconArrowRight className="h-4 text-red" color="white" />
        </Button>
      </div>
    </div>
  )
}

export default AiChatInput
