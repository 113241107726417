import React from 'react'

import ActionButtons from '../ActionButtons'
import SuspenseImage from '@components/SuspenseImage'

export interface ArticleProps {
  title: string
  text: string
  image: string
  hasActionButtons?: boolean
}

export const Article: React.FC<ArticleProps> = ({ title, text, image, hasActionButtons = false }) => {
  return (
    <div className="flex justify-between items-center gap-3 flex-col-reverse md:flex-row md:even:flex-row-reverse">
      <div className="flex flex-col gap-3 max-w-sm flex-grow">
        <h4 className="font-medium">{title}</h4>
        <p>{text}</p>
        {hasActionButtons && <ActionButtons />}
      </div>
      <div className="max-w-sm">
        <SuspenseImage src={image} alt={title} />
      </div>
    </div>
  )
}

export default Article
