import React from 'react'
import cx from 'clsx'
import { useParams, Outlet, useLocation, generatePath } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import Header from '@components/Header'
import CustomHeader from '../Header'

import { AccountingKeys } from '@queries/Keys'
import routes from '@src/Routes/routes'
import { useGetContact } from '@queries/Accounting'
import { useGetContactParty } from '../../utils'

import { Contact } from '@models/ApproveitAccounting'

export interface ContactLayoutProps {}

export const ContactLayout: React.FC<ContactLayoutProps> = () => {
  const { id: rawId, contactId } = useParams<{ contactId: string; id: string }>()
  const { party } = useGetContactParty()
  const { pathname, state } = useLocation<{ organization: string }>()

  const cache = useQueryClient()

  const id = contactId || rawId

  useGetContact(id !== 'new' ? contactId || id : undefined, party)
  const contact = cache.getQueryData<Contact>([AccountingKeys.Contacts, contactId || id])

  return (
    <div className={cx('flex flex-col h-full justify-between', { 'overflow-hidden': !pathname.includes('contracts') })}>
      <Header
        buttons={<div id="button-portal-container-contact" />}
        backUrl={
          state?.organization
            ? generatePath(routes.private.contactsList, { contactParty: party })
            : generatePath(routes.private.contacts, { contactParty: party })
        }
        title={contact?.name}
        className="!mb-0"
      />
      <CustomHeader id={contactId || id} />
      <Outlet />
    </div>
  )
}

export default ContactLayout
