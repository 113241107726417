import React from 'react'
import cx from 'clsx'
import {
  ColumnDef,
  FilterFnOption,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  Row,
  Cell,
} from '@tanstack/react-table'

interface TableProps<TRecord extends object> {
  classes?: {
    container?: string
    header?: string
    tr?: (row: Row<TRecord>) => string
    td?: (row: Cell<TRecord, unknown>) => string
  }
  data: TRecord[]
  columns: ColumnDef<TRecord>[]
  globalFilter?: Record<string, string>
  globalFilterFn?: FilterFnOption<TRecord>
}

const Table = <TRecord extends object>({
  data,
  columns,
  globalFilter,
  globalFilterFn,
  classes = {},
}: TableProps<TRecord>) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false,
    sortingFns: {},
  })

  const rowModel = table.getRowModel()

  return (
    <div
      className={cx(
        'rounded-base bg-white max-w-full overflow-x-auto w-full border border-slate-200',
        classes.container,
      )}
    >
      {rowModel.rows?.length ? (
        <table className="border-spacing-0 h-[1px] w-full rounded-base">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <React.Fragment key={headerGroup.id}>
                  <tr className={cx('rounded-t bg-slate-200', classes.header)}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} className="py-1 px-2.5 text-start">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
          </thead>
          <tbody className="rounded-b-base">
            {table.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr
                    className={cx(
                      'group first:border-t-none border-t border-slate-200 h-full last:rounded-b-base',
                      classes.tr?.(row),
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={`${cell.id}-${row.id}`}
                          className={cx(
                            'px-2.5 py-1 h-full group-last:first:rounded-bl-base group-last:last:rounded-br-base',
                            classes.td?.(cell),
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="py-2 flex justify-center">No records</div>
      )}
    </div>
  )
}

export default Table
