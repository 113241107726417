import React, { useCallback, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import cx from 'clsx'

import Tooltip from '@components/Tooltip'
import { IconLock } from '@tabler/icons-react'

export interface ContentLockProps {
  href?: string
  to?: string
  className?: string
  tooltipText?: string
}

export const ContentLock: React.FC<ContentLockProps> = ({ href, to, tooltipText, className }) => {
  const { t } = useTranslation('paywalls')
  const tooltipId = useId()
  const navigate = useNavigate()

  const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    if (href) {
      e.stopPropagation()
      window.open(href, '_blank')
    }
    if (to) {
      e.stopPropagation()
      navigate(to)
    }
  }, [])
  return (
    <div
      className={cx(
        'font-normal pointer-events-auto rounded-base border border-amber-200 bg-yellow-50 text-amber-700 px-0.5 py-0.5 transition-shadow group-hover:shadow-[0_0_0_3px_rgba(144,155,250,0.14)] hover:shadow-[0_0_0_3px_rgba(144,155,250,0.14)]',
        className,
      )}
      data-tooltip-id={tooltipId}
      onClick={onClick}
    >
      <IconLock className="h-4" />
      <Tooltip id={tooltipId}>
        <span>{tooltipText || t('lockTooltip')}</span>
      </Tooltip>
    </div>
  )
}

export default ContentLock
