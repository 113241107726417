import { useCreate, useDelete, useGetOne } from '@queries/request'
import { IntegrationKeys } from '@queries/Keys'
import { useQueryClient } from '@tanstack/react-query'

const PATHS = {
  integration: '/integrations/chatgpt',
}

export const useGetApproveitAiIntegrationData = () => {
  const cache = useQueryClient()

  return useGetOne<{
    reporting_period_month: number
  }>([IntegrationKeys.ApproveitAI], PATHS.integration, {
    query: {
      onError: () => {
        cache.setQueryData([IntegrationKeys.ApproveitAI], null)
      },
    },
  })
}

export const useSaveApproveitAIIntegration = () => {
  const cache = useQueryClient()
  return useCreate(PATHS.integration, {
    mutation: {
      mutationKey: [IntegrationKeys.ApproveitAISave],
      onSuccess: () => {
        cache.invalidateQueries([IntegrationKeys.ApproveitAI])
      },
    },
  })
}

export const useDeleteApproveitAIIntegration = () => {
  const cache = useQueryClient()
  return useDelete(PATHS.integration, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([IntegrationKeys.ApproveitAI])
      },
    },
  })
}
