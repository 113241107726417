import React, { useState } from 'react'
import cx from 'clsx'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconMenu2, IconMessage2, IconRocket } from '@tabler/icons-react'

import Loading from '../Loading'
import BurgerMenu from './BurgerMenu'
import UserMenu from './components/UserMenu'
import HelpMenu from './components/HelpMenu'
import QuickMenu from './components/QuickMenu'
import OnboardingProgress from './components/OnboardingProgress'
import TrialCounter from './components/TrialCounter'
import Tooltip from '@components/Tooltip'

import AIAssistantButton from '@components/Navigation/components/AIAssistantButton'
import { useGetMyAccount } from '@queries/Account'
import { useHandleClickVendors } from '@helpers/hooks/useHandleClickVendors'
import useWindowSize from '@src/helpers/hooks/useWindowSize'
import { useIsAdmin, useIsMobile } from '@src/helpers'
import { useWindowControlsOverlay } from './utils'
import routes from '@src/Routes/routes'
import useStore from '@src/store'
import { useGetTeamSubscription } from '@queries/Billing'
import { useGetShowAIReports } from '@helpers/hooks/useGetShowAIReports'
import Button from '../Button'
import { usePopupHandlers } from '../Popup'
import PlansListPopup from '@pages/Billing/PlansListPopup'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import styles from './index.module.css'
import MaintenaceBanner from './components/MaintenanceBanner'
import { SHOW_MAINTENANCE_BANNER } from '@src/constants'
import { Role } from '@src/types/common'

interface NavigationProps {
  children?: React.ReactNode
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
}

const Navigation: React.FC<NavigationProps> = ({ children, isCollapsed, setIsCollapsed }) => {
  const { t } = useTranslation('common')
  const [showRedDot, setShowRedDot] = useState(() => {
    const clickedBefore = localStorage.getItem('chatClicked')
    if (clickedBefore === null) navigator?.setAppBadge?.(1)
    return !clickedBefore
  })
  const { data: user } = useGetMyAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })
  const isWindowControlsOverlay = useWindowControlsOverlay()
  const showReports = useGetShowAIReports()
  const { handleTogglePopup, setIsPopupOpen, isPopupOpen } = usePopupHandlers()

  const isOnboarding = useStore.use.isOnboarding()

  const isMobile = useIsMobile()
  const { width } = useWindowSize()
  const BURGER_BREAKPOINT = 768
  const isAdmin = useIsAdmin()
  const hasAccess = user?.team?.onboarded
  const { clickVendorState } = useHandleClickVendors()

  const handleChatButtonClick = () => {
    setShowRedDot(false)
    localStorage.setItem('chatClicked', 'true')
    navigator?.clearAppBadge?.()
  }

  return (
    <>
      {isPopupOpen && <PlansListPopup isPopupVisible={isPopupOpen} setIsPopupVisible={setIsPopupOpen} />}
      <Loading
        loading={clickVendorState.isLoading}
        redirectTo={clickVendorState.redirectTo}
        redirectURL={clickVendorState.redirectURL}
      />
      {hasAccess && !isOnboarding && subscription?.is_trial && <TrialCounter />}
      {SHOW_MAINTENANCE_BANNER && !isOnboarding && !subscription?.is_trial && <MaintenaceBanner />}
      {hasAccess && (
        <div
          id="navigation"
          // it works only with z-index equal or more 10billon
          className={cx(styles.navPanel, { 'z-[10000000000]': isOnboarding, 'z-10': !isOnboarding })}
          onContextMenu={(e) => e.preventDefault()}
        >
          {!isMobile && (
            <>
              <Tooltip offset={10} id="tooltip_chat" place="bottom" />
            </>
          )}
          <div id="start" className={cx('flex gap-5 items-center', styles.startNav)}>
            {isMobile || width < BURGER_BREAKPOINT ? (
              !isWindowControlsOverlay && <BurgerMenu isDisabled={!hasAccess} />
            ) : (
              <div
                className={cx(styles.burgerButtonWrap, 'relative group')}
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <button
                  className={cx(
                    'group-hover:opacity-10 group-active:opacity-20 group-active:outline cursor-default',
                    styles.burgerButton,
                  )}
                />
                <IconMenu2 className="relative z-10 h-6 w-6 text-black cursor-pointer" stroke={1.5} />
              </div>
            )}
            <NavLink
              to={routes.private.dashboard}
              className={cx(styles.companyLogoWrap, 'hidden xs:inline-block active:outline outline-[3px]', {
                'pointer-events-none': isOnboarding,
              })}
            >
              <img className={styles.companyLogo} alt="Approveit" src="/logo.svg" />
              Approveit
            </NavLink>
            {hasAccess && !isOnboarding && subscription?.is_trial && user.role === Role.admin && (
              <Button
                onClick={handleTogglePopup}
                type="outlined"
                className="text-sm sm:text-base px-1 sm:px-4 border-2"
                color="primary"
              >
                {t('trialCounter.upgradeLabel')} <IconRocket className="h-5 w-5" />
              </Button>
            )}
          </div>
          {isOnboarding && (
            <div className="grow flex justify-center">
              <OnboardingProgress />
            </div>
          )}
          {!isOnboarding && (
            <div id="end" className={cx('flex gap-1.5', styles.endNav)}>
              <QuickMenu />
              <button
                className={cx(
                  styles.navLinkButtonWrap,
                  styles.chatButton,
                  showRedDot && styles.showRedDot,
                  'active:scale-95',
                )}
                data-tooltip-content={t('navigation.chat')}
                data-tooltip-id="tooltip_chat"
                id="intercom-chat"
                onClick={handleChatButtonClick}
              >
                <IconMessage2 className={styles.endMenuIcon} stroke={1.5} />
              </button>
              {showReports && isAdmin && <AIAssistantButton />}
              <HelpMenu />
              <UserMenu />
            </div>
          )}
        </div>
      )}
      {children}
    </>
  )
}

export default Navigation
