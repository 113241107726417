import { BillingKeys, AccountKeys } from '../Keys'
import { useCreate, useDelete, useGetList, useGetOne, usePatch } from '../request'

import {
  CheckoutStripeRequest,
  UpgradeStripeRequest,
  Subscription,
  UnsubscribeQuestion,
  UnsubscribeQuestionAnswer,
} from '@models/Billing'
import { useQueryClient } from '@tanstack/react-query'

const PATHS = {
  stripeCheckout: 'api/stripe/checkout',
  subscription: 'api/teams/subscription',
  unsubscribeQuestion: 'api/teams/unsubscribe-questions',
  unsubscribeQuestionAnswer: 'api/teams/unsubscribe-questionnaire',
}
export const useGetTeamSubscription = ({ enabled = true, suspense = false } = {}) => {
  return useGetOne<Subscription>([BillingKeys.Subscription], PATHS.subscription, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense,
      enabled,
    },
  })
}

export const useCheckoutStripe = () => {
  return useCreate<CheckoutStripeRequest, { checkout_url: string }>(PATHS.stripeCheckout, {
    mutation: { mutationKey: [BillingKeys.StripeCheckout] },
  })
}

export const useUpgradeStripe = () => {
  const cache = useQueryClient()
  return usePatch<UpgradeStripeRequest, { checkout_url: string }>(PATHS.subscription, {
    mutation: {
      mutationKey: [BillingKeys.StripeCheckout],
      onSuccess: () => cache.invalidateQueries([BillingKeys.Subscription]),
    },
  })
}

export const useCancelSubscription = () => {
  const cache = useQueryClient()
  return useDelete(PATHS.subscription, {
    mutation: {
      mutationKey: [BillingKeys.CancelSubscription],
      onSuccess: () => {
        cache.invalidateQueries([BillingKeys.Subscription])
        cache.invalidateQueries([AccountKeys.Me])
      },
    },
  })
}

export const useGetUnsubscribeQuestion = () => {
  return useGetList<UnsubscribeQuestion>([BillingKeys.UnsubscribeQuestion], PATHS.unsubscribeQuestion, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useSendUnsubscribeAnswer = () => {
  return useCreate<UnsubscribeQuestionAnswer>(PATHS.unsubscribeQuestionAnswer)
}
