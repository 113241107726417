import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'

import Actions from './componets/Actions'

import { BudgetFrequencyOption } from '../utils/constants'

import { ListItem } from './types'
import { BudgetFrequency } from '@type/budget'
import { val2Amount } from '@helpers/utils'

export const useGetTableColumns = () => {
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.list.columns' })
  return useMemo<Column<ListItem>[]>(
    () => [
      {
        Header: t('nameLabel'),
        accessor: 'name',
      },
      {
        Header: t('codeLabel'),
        accessor: 'code',
      },
      {
        Header: t('owner'),
        accessor: 'owner',
      },
      {
        Header: t('budgetFrequency'),
        accessor: (row) => {
          return BudgetFrequencyOption[row.budget_frequency || BudgetFrequency.custom]?.label
        },
      },
      {
        Header: t('currency'),
        accessor: 'currency',
      },
      {
        Header: t('currentBudget'),
        accessor: (record) => val2Amount(record.budget_allocation_amount || 0, record.currency),
      },
      {
        id: 'actions',
        accessor: (record) => <Actions record={record} />,
      },
    ],
    [],
  )
}
