import React from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'

import styles from '../../index.module.css'
import routes from '@src/Routes/routes'

const TabNavigation = () => {
  const { id } = useParams()
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter' })
  return (
    <div className="flex gap-3">
      <NavLink
        className={({ isActive }) => cx(styles.tab, isActive && styles.active)}
        to={generatePath(routes.private.costCenterBudget, { id })}
      >
        {t('budgetTab.title')}
      </NavLink>
      {/* <NavLink */}
      {/*   className={({ isActive }) => cx(styles.tab, isActive && styles.active)} */}
      {/*   to={generatePath(routes.private.costCenterBreakdown, { id })} */}
      {/* > */}
      {/*   {t('breakdownTab.title')} */}
      {/* </NavLink> */}
    </div>
  )
}
export default TabNavigation
