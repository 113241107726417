import React, { useEffect } from 'react'
import cx from 'clsx'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useGetMyAccount } from '@queries/Account'

import ErrorFallback from '@components/ErrorBoundary/ErrorBoundaryFallback'
import Loading from '@components/Loading'
import Navigation from '@components/Navigation'
import SlackConnectionPopup from '@components/SlackConnectionPopup'
import LeftNav from '../Navigation/LeftNav'

import { useGetTeamSubscription } from '@queries/Billing'
import useStore from '@src/store'
import useLocalStorage from '@helpers/hooks/useLocalStorage'
import { useGetMaintenanceBanner } from '@helpers/hooks/maintenace/useGetMaintenanceBanner'
import routes from '@src/Routes/routes'

import styles from './index.module.css'
import { SHOW_MAINTENANCE_BANNER } from '@src/constants'

export interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { data: user } = useGetMyAccount()

  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })

  const [maintenace] = useGetMaintenanceBanner()

  const isOnboarding = useStore.use.isOnboarding()
  const startPath = useStore.use.startPath()
  const onboardStep = useStore.use.onboardingStep()

  useEffect(() => {
    if (startPath && location.pathname !== startPath) {
      navigate(startPath)
    }
  }, [])

  const [isCollapsed, setIsCollapsed] = useLocalStorage('isCollapsed', false)

  const isLocked = !isOnboarding && !!onboardStep && !!startPath

  const hasBanner = subscription?.is_trial || (!subscription?.is_trial && SHOW_MAINTENANCE_BANNER && maintenace.show)

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {isLocked && <Loading loading />}
      <Navigation isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <div className="flex">
        <LeftNav setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
        <div
          id="container"
          className={cx(
            styles.container,
            location.pathname === routes.private.onboarding && 'p-0 !h-screen',
            location.pathname.includes(routes.private.settings) && 'p-0',
            hasBanner && !isOnboarding && styles.banner,
          )}
        >
          <React.Suspense fallback={<Loading loading />}>
            <SlackConnectionPopup />
            <Outlet />
          </React.Suspense>
        </div>
      </div>
    </Sentry.ErrorBoundary>
  )
}

export default Layout
