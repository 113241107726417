import { useState, useCallback } from 'react'

export const useHandleClickVendors = () => {
  const [clickVendorState, setState] = useState({
    isLoading: false,
    redirectTo: '',
    redirectURL: '',
  })

  const handleClickVendor = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setState({
      isLoading: true,
      redirectTo: e.currentTarget.dataset.vendor || '',
      redirectURL: e.currentTarget.href,
    })
  }, [])

  return { clickVendorState, handleClickVendor }
}
