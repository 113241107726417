import React, { FC, forwardRef } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-highlight'
import cx from 'clsx'

import Avatar from '@components/Avatar'

import useGetAccount from '@helpers/hooks/useGetAccount'

import styles from '../index.module.css'

import { ConversationItem, Sender } from '@components/AIChat/type'
import { useTranslation } from 'react-i18next'

interface ConversationProps {
  messages: ConversationItem[]
  isAwaitingAnswer?: boolean
}

interface Message {
  senderName: string
  isUserMessage?: boolean
  message?: string
}

const Message: FC<Message> = ({ senderName, isUserMessage, message }) => {
  const Image = !isUserMessage ? <img src="/logo/approveit-ai.svg" alt="Approveit AI" /> : undefined
  return (
    <div className="flex flex-col">
      <div className="flex gap-3 items-center mb-4">
        <Avatar
          name={senderName}
          Image={Image}
          className={cx({
            'bg-gray-200': !isUserMessage,
          })}
        />
        <span className="font-bold text-lg">{senderName}</span>
      </div>
      {message ? (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeHighlight]}
          components={{
            a: ({ ...props }) => (
              <a {...props} className="text-blue-500 hover:underline cursor-pointer">
                {props.children}
              </a>
            ),
            code: ({ ...props }) => (
              <div className="rounded p-2 bg-gray-100">
                <code className="whitespace-pre-wrap">{props.children}</code>
              </div>
            ),
          }}
        >
          {message}
        </ReactMarkdown>
      ) : (
        <div className={styles.loader} />
      )}
    </div>
  )
}

const Conversation = forwardRef<HTMLDivElement, ConversationProps>(
  ({ messages = [], isAwaitingAnswer }, bottomOfMessagesRef) => {
    const user = useGetAccount()
    const { t } = useTranslation('reports', { keyPrefix: 'ai' })
    return (
      <>
        <div className="flex flex-col gap-10 mt-7">
          {messages.map((item, i) => {
            const isUserMessage = item.sender === Sender.me
            const senderName = isUserMessage && user ? user.name : t('assistantName')
            return <Message key={i} senderName={senderName} message={item?.message} isUserMessage={isUserMessage} />
          })}
          {isAwaitingAnswer && <Message senderName={t('assistantName')} isUserMessage={false} />}
        </div>
        <div ref={bottomOfMessagesRef} />
      </>
    )
  },
)

export default Conversation
