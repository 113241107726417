import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { IconRocket } from '@tabler/icons-react'
import Button from '@components/Button'
import PlansListPopup from '@pages/Billing/PlansListPopup'
import LinkButton from '@components/LinkButton'

import { usePopupHandlers } from '@components/Popup/usePopupHandler'
import useIsMSTeams from '@helpers/hooks/useIsMSTeams'
import { MS_CONTACT_US_LINK } from '@pages/Billing/constants'

export interface ActionButtonsProps {
  className?: string
  buttonClassName?: string
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ className, buttonClassName }) => {
  const { t } = useTranslation('paywalls')
  const isMS = useIsMSTeams()

  const { setIsPopupOpen, handleTogglePopup, isPopupOpen } = usePopupHandlers()

  return (
    <>
      {isPopupOpen && <PlansListPopup isPopupVisible={isPopupOpen} setIsPopupVisible={setIsPopupOpen} />}
      <div className={cx('flex gap-2 flex-col flex-shrink sm:flex-row', className)}>
        <Button onClick={handleTogglePopup} className={cx('sm:w-40', buttonClassName)}>
          <IconRocket />
          {t('upgradeLabel')}
        </Button>
        {!isMS ? (
          <Button type="outlined" id="intercom-chat" className={cx('sm:w-40', buttonClassName)}>
            {t('contactUsLabel')}
          </Button>
        ) : (
          <LinkButton
            type="outlined"
            id="intercom-chat"
            target="_blank"
            className={cx('sm:w-40', buttonClassName)}
            to={MS_CONTACT_US_LINK}
          >
            {t('contactUsLabel')}
          </LinkButton>
        )}
      </div>
    </>
  )
}

export default ActionButtons
