import React from 'react'
import cx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'

import routes from '@src/Routes/routes'

import styles from './index.module.css'
import { useTranslation } from 'react-i18next'
import { useIsAdmin } from '@src/helpers'

export interface HeaderProps {
  children?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ children }) => {
  const location = useLocation()
  const { t } = useTranslation('team')
  const isAdmin = useIsAdmin()

  return (
    <div className="flex justify-between border-b flex-wrap-reverse gap-0.5 md:gap-2 mb-4 items-end">
      <nav className="flex">
        <NavLink
          to={routes.private.organizationalStructure}
          className={({ isActive }) => {
            return cx(styles.tab, { [styles.active]: isActive && !location.pathname.includes('contract') })
          }}
        >
          {t('users.title')}
        </NavLink>
        {isAdmin && (
          <>
            <NavLink
              to={routes.private.teamDepartments}
              className={({ isActive }) => {
                return cx(styles.tab, { [styles.active]: isActive && !location.pathname.includes('contract') })
              }}
            >
              {t('departments.title')}
            </NavLink>
            <NavLink
              to={routes.private.teamLocations}
              className={({ isActive }) => {
                return cx(styles.tab, { [styles.active]: isActive && !location.pathname.includes('contract') })
              }}
            >
              {t('locations.title')}
            </NavLink>
          </>
        )}
      </nav>
      {children}
    </div>
  )
}

export default Header
