import React, { useMemo } from 'react'
import { createColumnHelper, FilterFn } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { useGetMyAccount } from '@queries/Account'
import { val2Amount } from '@helpers/utils'
import { getAmounts } from '@pages/Accounting/CostCenter/utils'

import { BudgetAllocationResponse } from '@models/CostCenters'
import { GlobalBudgetFilter } from '../../types'
import Table from '@pages/Accounting/CostCenter/Details/components/Table'

const columnHelper = createColumnHelper<BudgetAllocationResponse>()

export interface SpendingCategoriesListProps {
  data: BudgetAllocationResponse[]
  startDate?: string
}

const startDateFilter: FilterFn<BudgetAllocationResponse> = (row, columnId, filterOption: GlobalBudgetFilter) => {
  return filterOption.startDate === row.original.start_date
}

export const SpendingCategoriesList: React.FC<SpendingCategoriesListProps> = ({ data, startDate }) => {
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.details' })
  const { data: user } = useGetMyAccount()
  const currency = user?.team?.default_currency

  const globalFilter: GlobalBudgetFilter = useMemo(() => ({ startDate }), [startDate])

  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => row.accounting_category?.name || 'Uncategorized', {
        header: t('categoryLabel'),
      }),
      columnHelper.accessor((row) => val2Amount(row.amount || 0, currency), {
        header: t('allocatedLabel'),
      }),
      columnHelper.accessor(
        (row) => getAmounts(row.amount, row.completed_amount, row.committed_amount, currency).remaining,
        {
          header: t('remainingLabel'),
        },
      ),
      columnHelper.accessor((row) => val2Amount(row.committed_amount || 0, currency), {
        header: t('commitedLabel'),
      }),
      columnHelper.accessor((row) => val2Amount(row.completed_amount || 0, currency), {
        header: t('spentLabel'),
      }),
      columnHelper.accessor(
        (row) => getAmounts(row.amount, row.completed_amount, row.committed_amount, currency).overspent,
        {
          header: t('overspendLabel'),
        },
      ),
    ]
  }, [])

  return (
    <Table
      data={data}
      columns={columns}
      globalFilterFn={startDateFilter}
      globalFilter={globalFilter}
      classes={{
        tr: (row) => row.original.accounting_category ? 'bg-white' : 'bg-indigo-50',
      }}
    />
  )
}

export default React.memo(SpendingCategoriesList)
