import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { IconBell, IconBellOff, IconPencil, IconTrash } from '@tabler/icons-react'
import { Trans, useTranslation } from 'react-i18next'

import ButtonWithConfirmation from '@components/Button/ButtonWithConfirmation'
import Tooltip from '@components/Tooltip'
import Button from '@components/Button'

import { useDeleteCostCenter, useUpdateCostCenter } from '@src/api/queries/Accounting/costCenter'
import { requestErrorsHandler } from '@helpers/utils'
import routes from '@src/Routes/routes'

import { ListItem } from '../types'
import styles from '../index.module.css'

export interface ActionsProps {
  record: ListItem
}

export const Actions: React.FC<ActionsProps> = ({ record }) => {
  const { mutateAsync: deleteCostCenter } = useDeleteCostCenter(record.id)
  const { mutateAsync: updateCostCenter, isLoading } = useUpdateCostCenter(record.id)
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.list' })

  const [notificationsEnabled, setNotificationsEnabled] = React.useState(record.enable_notifications)
  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      if (record.id) {
        await deleteCostCenter({})
      }
    } catch (err) {
      requestErrorsHandler(err)
    }
  }

  return (
    <div className="flex gap-1.5 justify-end text-brand_primary">
      <Link
        to={{
          pathname: generatePath(routes.private.costCenterEditById, { id: record.id }),
        }}
        title="Edit"
        onClick={(e) => e.stopPropagation()}
        data-tooltip-content="Edit cost center"
        data-tooltip-id="tooltip_actions"
      >
        <IconPencil className={styles.actions} />
      </Link>
      <Button
        type="contained"
        className="p-0"
        tooltip={notificationsEnabled ? t('disableNotificationTooltip') : t('enableNotificationTooltip')}
        disabled={isLoading}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setNotificationsEnabled((notificationsEnabled) => !notificationsEnabled)
          updateCostCenter({ enable_notifications: !notificationsEnabled })
        }}
      >
        {notificationsEnabled ? <IconBell className="h-4 w-4" /> : <IconBellOff className="h-4 w-4" />}
      </Button>
      <ButtonWithConfirmation
        text={t('popupDelete.title')}
        subText={<Trans components={{ b: <strong /> }}>{t('popupDelete.text', { center: record.name })}</Trans>}
        confirmType="common"
        onClick={handleDeleteClick}
        buttonType="button"
        type="contained"
        className="mr-2 p-0 h-auto"
        data-tooltip-content="Delete cost center"
        data-tooltip-id="tooltip_actions"
      >
        <IconTrash className={styles.actions} />
      </ButtonWithConfirmation>
      <Tooltip id="tooltip_actions" place="left" />
    </div>
  )
}

export default Actions
