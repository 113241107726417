import React from 'react'
import { Outlet } from 'react-router-dom'

import TabNavigation from '@pages/Reports/List/components/TabNavigation'
import Divider from '@components/Divider'

const ReportsList = () => {
  return (
    <div className="h-full flex flex-col">
      <TabNavigation />
      <Divider className="mt-0" />
      <Outlet />
    </div>
  )
}

export default ReportsList
