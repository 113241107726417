import React, { FC } from 'react'
import cx from 'clsx'
import { getInitials } from '@helpers/utils'
import { IconCircleCheckFilled } from '@tabler/icons-react'

interface AvatarProps {
  name: string
  Image?: React.ReactElement
  className?: string
  assigned?: boolean
}

const Avatar: FC<AvatarProps> = ({ name, Image, className = '', assigned = false, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        className,
        assigned ? '' : 'overflow-hidden',
        'relative flex items-center justify-center w-8 h-8 flex-shrink-0 rounded-full bg-blue-500 text-center text-white select-none',
      )}
    >
      {assigned && (
        <IconCircleCheckFilled
          size={8}
          className="absolute -bottom-[1px] -right-[1px] text-emerald-500 bg-white rounded-full outline outline-[0.5px] outline-white"
        />
      )}
      {Image || <span>{getInitials(name)}</span>}
    </div>
  )
}

export default Avatar
