import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import Popup from '@components/Popup'
import { Input, Type } from '@components/Form/Input'
import Button from '@components/Button'
import { useContactSales } from '@queries/Account'
import useGetAccount from '@helpers/hooks/useGetAccount'
import { toast } from '@src/helpers/toaster'
import { ContactType } from '@type/common'

const MESSAGE_PREFIX = 'FEATURE_SUGGESTION: '

interface SuggestFeatureModal {
  onClose(): void
}

const SuggestFeatureModal: FC<SuggestFeatureModal> = ({ onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'suggestFeature' })
  const { mutateAsync: contactSales, isLoading, isSuccess } = useContactSales()
  const account = useGetAccount()

  const { handleChange, values, errors, handleSubmit } = useFormik({
    initialValues: { suggestionText: '' },
    validationSchema: Yup.object().shape({
      suggestionText: Yup.string().max(255, 'Value can not be longer than 255 characters'),
    }),
    onSubmit: (values) => {
      if (!values.suggestionText) {
        toast(t('errors.emptyForm'))
        return
      }
      contactSales({
        email: account?.email || '',
        full_name: account?.name || '',
        notes: MESSAGE_PREFIX + values.suggestionText,
        features: values.suggestionText,
        type: ContactType.featureSuggestion,
      }).catch((e) => {
        toast(e.message)
      })
    },
  })

  const content = useMemo(() => {
    if (isSuccess) {
      return (
        <div className="flex flex-col items-center gap-3 ">
          <img className="w-full" src="/icons/suggest_feature.svg" />
          <h4 className="text-center text-brand_primary font-weight-bold">{t('submitted.title')}</h4>
          <h5 className="text-center">{t('submitted.description')}</h5>
          <Button className="w-[128px] my-3" onClick={onClose}>
            {t('buttons.ok')}
          </Button>
        </div>
      )
    }

    return (
      <form className="p-[30px] gap-3 flex flex-col items-center" onSubmit={handleSubmit}>
        <h4 className="text-center text-brand_primary font-weight-bold">{t('form.title')}</h4>
        <h5 className="text-center">{t('form.description')}:</h5>
        <Input
          type={Type.textarea}
          placeholder={t('form.placeholder')}
          className="w-full"
          onChange={handleChange}
          name="suggestionText"
          errorMessage={errors?.suggestionText}
        />
        <Button className="max-w-[128px]" buttonType="submit" loading={isLoading} disabled={!!errors?.suggestionText}>
          {t('buttons.submit')}
        </Button>
      </form>
    )
  }, [isSuccess, isLoading, values.suggestionText, errors?.suggestionText])

  return (
    <Popup onClose={onClose} className={clsx('max-w-[680px] rounded-2xl', isSuccess && 'p-0')}>
      {content}
    </Popup>
  )
}

export default SuggestFeatureModal
