import { SHOW_ORGS_FOR } from '@src/constants'
import { useMemo } from 'react'
import useGetAccount from './useGetAccount'

export const useGetShowOrgs = () => {
  const account = useGetAccount()

  return useMemo(() => {
    const teams =
      SHOW_ORGS_FOR?.split(',')
        ?.map((id: string) => id?.trim())
        .filter(Boolean) || []

    if (!teams.length) {
      return true
    }
    if (account?.team) {
      return teams.includes(account?.team?.id?.toString())
    }

    return false
  }, [account])
}
