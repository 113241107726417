export const enum BudgetFrequency {
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
  custom = 'custom',
}

export enum DeductedSumType {
  gross = 'gross',
  net = 'net',
}
