import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface XeroProps {}

const HERO_IMAGE = '/paywalls/xero/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:xero.articles.sync.title'),
    text: i18n.t('paywalls:xero.articles.sync.text'),
    image: '/paywalls/xero/sync.png',
  },
  {
    title: i18n.t('paywalls:xero.articles.approval.title'),
    text: i18n.t('paywalls:xero.articles.approval.text'),
    image: '/paywalls/xero/approval.png',
  },
  {
    title: i18n.t('paywalls:xero.articles.entities.title'),
    text: i18n.t('paywalls:xero.articles.entities.text'),
    image: '/paywalls/xero/entities.png',
  },
]

export const Xero: React.FC<XeroProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'xero' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section title={t('section.title')} text={t('section.text')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article, index) => (
          <Article
            key={article.title}
            title={article.title}
            image={article.image}
            text={article.text}
            hasActionButtons={index === ARTICLES.length - 1}
          />
        ))}
      </div>
    </>
  )
}

export default Xero
