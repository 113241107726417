import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { IconX } from '@tabler/icons-react'

import Button from '@components/Button'

import { useGetMaintenanceBanner } from '@helpers/hooks/maintenace/useGetMaintenanceBanner'

export interface MaintenanceBannerProps {}

export const MaintenanceBanner: React.FC<MaintenanceBannerProps> = () => {
  const maintenanceStore = useGetMaintenanceBanner()

  const [maintenace, setMaintenace] = maintenanceStore

  useEffect(() => {
    if (!maintenace.show && maintenace.expiration) {
      const diff = (maintenace.expiration || 0) - new Date().getTime()
      if (diff <= 0) {
        setMaintenace({ show: true, expiration: null })
      }
    }
  }, [])

  return maintenace?.show ? (
    <div className="h-12 px-2 py-1 text-sm sm:text-base bg-gradient-to-r from-brand_primary to-teal-500 text-white flex items-center justify-between gap-2">
      <p className="text-center grow">
        We're performing maintenance
        <span className="hidden sm:inline"> to make the app faster and add new features</span>! You may experience brief
        disruptions. Thanks for your patience!
      </p>
      <Button
        onClick={() => setMaintenace({ show: false, expiration: addDays(new Date(), 1).getTime() })}
        type="contained"
        color="white"
        className="min-w-0 p-0"
      >
        <IconX className="h-5 w-5" />
      </Button>
    </div>
  ) : null
}

export default MaintenanceBanner
