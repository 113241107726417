import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import i18n from '@src/i18n'
import { BudgetAllocation, Form } from '../types'

export const useCostCenterContext = () => useFormikContext<Form>()

export const budgetAllocationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('accounting:costCenter.form.validation.required')),
  startDate: Yup.date().required(i18n.t('accounting:costCenter.form.validation.required')),
  endDate: Yup.date()
    .required(i18n.t('accounting:costCenter.form.validation.required'))
    .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
  amount: Yup.number()
    .nullable()
    .required(i18n.t('accounting:costCenter.form.validation.required'))
    .min(0, i18n.t('accounting:costCenter.form.validation.minValue', { min: 0 }))
    .test('sum', (_, ctx) => {
      const budget = ctx.parent as BudgetAllocation
      if (budget.isUncategorized) {
        const costCenter = ctx.options.context as Form
        const sum = costCenter.budgetAllocations.reduce((sum, currBudget) => {
          if (!currBudget.isUncategorized && budget.name === currBudget.name) {
            return (sum * 100 + currBudget.amount * 100) / 100
          }
          return sum
        }, 0)
        if (sum <= budget.amount) {
          return true
        }
        return ctx.createError({
          message: `The sum of category budgets cannot be more than the allocated amount for ${budget.name}. Please check the budget data and try again later.`,
        })
      }

      return true
    }),
})

const CodeTemplate = /^[A-Za-z0-9_:\/.-]+$/

export const Schema = Yup.object().shape({
  name: Yup.string().max(90, i18n.t('accounting:costCenter.form.validation.maxLength', { max: 90 })),
  code: Yup.string().matches(CodeTemplate, i18n.t('accounting:costCenter.form.validation.invalidCode')),
  budgetAllocations: Yup.array().of(budgetAllocationSchema),
  owner: Yup.number().required(i18n.t('accounting:costCenter.form.validation.required')),
})
