import { useMemo } from 'react'

import { NavItem } from './menuItems'

import { Account } from '@models/Account'
import { Role } from '@type/common'

export const useWindowControlsOverlay = () => {
  return useMemo(() => {
    return navigator.windowControlsOverlay !== undefined && navigator.windowControlsOverlay.visible
  }, [navigator.windowControlsOverlay?.visible])
}

export const filterNavItems = (items: NavItem[], user?: Account, showNavsWithRestriction?: Record<string, boolean>) => {
  const isAdmin = user?.role === Role.admin
  const canViewAdminStructure = user?.team?.settings?.orgstructure_visibility === Role.admin.toLowerCase()
  return items.filter((item) => {
    const isAllowed =
      item.to && showNavsWithRestriction?.[item.to] !== undefined ? showNavsWithRestriction[item.to] : true
    if (item.roleVisibilityPermission) {
      return canViewAdminStructure ? isAdmin : true
    }
    return isAllowed && (!item.show || (user && item.show.includes(user.role)))
  })
}
