import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface MailboxProps {}

const HERO_IMAGE = '/paywalls/mailbox/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:mailbox.articles.mailbox.title'),
    text: i18n.t('paywalls:mailbox.articles.mailbox.text'),
    image: '/paywalls/mailbox/mailbox.png',
  },
]

export const Mailbox: React.FC<MailboxProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'mailbox' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section title={t('section.title')} text={t('section.text')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article, index) => (
          <Article
            key={article.title}
            title={article.title}
            image={article.image}
            text={article.text}
            hasActionButtons={index === ARTICLES.length - 1}
          />
        ))}
      </div>
    </>
  )
}

export default Mailbox
