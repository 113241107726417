import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Popup, { usePopupHandlers } from '@components/Popup'
import Button from '@components/Button'
import PlansPopup from '../components/PlansPopup'

import { useStartNewPlan } from '../hooks/useStartNewPlan'
import { useGetTeamSubscription } from '@queries/Billing'
import { requestErrorsHandler } from '@helpers/utils'
import { toast } from '@helpers/toaster'

import { plansData } from '../constants'

import { Plan } from '@type/common'

interface PlansListPopupProps {
  isPopupVisible: boolean
  setIsPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const PlansListPopup: FC<PlansListPopupProps> = ({ isPopupVisible, setIsPopupVisible }) => {
  const { t } = useTranslation('billing')

  const { data: subscription } = useGetTeamSubscription()
  const plan = plansData[subscription?.plan as Plan]

  const { handleStartNewPlanClick, upgradeSubscription, productPriceKey, setProductPriceKey } = useStartNewPlan()
  const {
    handleTogglePopup: handleToggleConfirmPopup,
    isPopupOpen: isConfirmPopupVisible,
    setIsPopupOpen: setIsConfirmPopupVisible,
  } = usePopupHandlers()

  const [clickedPlan, setClickedPlan] = useState<string>('')

  const handleClosePopup = () => {
    setProductPriceKey(null)
    setIsConfirmPopupVisible(false)
  }

  const handlePlanClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setClickedPlan(e.currentTarget.name)
      handleStartNewPlanClick(e)
    },
    [handleStartNewPlanClick],
  )

  const handleConfirmUpgradeClick = async () => {
    try {
      await upgradeSubscription()
      handleToggleConfirmPopup()
      toast.success(t('plans.planConfirmPopup.subscriptionUpgraded'))
    } catch (err) {
      requestErrorsHandler(err)
    }
  }

  useEffect(() => {
    if (productPriceKey) {
      setIsConfirmPopupVisible(true)
    }
  }, [productPriceKey])

  return (
    <>
      {isConfirmPopupVisible && (
        <Popup
          title={t('plans.planConfirmPopup.confirmationTitle')}
          onClose={handleClosePopup}
          className="w-full max-w-md mx-1 sm:mx-4 px-18"
        >
          {t('plans.planConfirmPopup.confirmationProText', {
            clickedPlan: clickedPlan,
            currentPlan: plan?.name.toLowerCase(),
          })}
          <div className="flex gap-1.5 justify-end mt-5">
            <Button onClick={handleConfirmUpgradeClick} color="success" className="font-medium">
              {t('plans.planConfirmPopup.confirm')}
            </Button>
            <Button className="font-medium" color="white" onClick={handleClosePopup}>
              {t('plans.planConfirmPopup.cancel')}
            </Button>
          </div>
        </Popup>
      )}
      {isPopupVisible && <PlansPopup setIsPopupVisible={setIsPopupVisible} onStartPlanClick={handlePlanClick} />}
    </>
  )
}

export default PlansListPopup
