import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import CustomHeader from '../Header'

export interface VendorLayoutProps {}

export const TeamLayout: React.FC<VendorLayoutProps> = () => {
  const [headerButtons, setHeaderButtons] = useState<React.ReactNode>(null)

  return (
    <div className="flex flex-col h-full">
      <CustomHeader>{headerButtons}</CustomHeader>
      <Outlet context={setHeaderButtons} />
    </div>
  )
}

export default TeamLayout
