import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { components, CSSObjectWithLabel } from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import cx from 'clsx'
import * as Yup from 'yup'

import Header from '@components/Header'
import { Input, Select } from '@components/Form'
import Button from '@components/Button'
import { IconPencil, IconX } from '@tabler/icons-react'

import routes from '@src/Routes/routes'
import { statuses } from '@pages/ApprovalRequests/RequestDetails/constants'
import { toast } from '@helpers/toaster'
import i18n from '@src/i18n'

import { Status } from '@type/common'
import { requestErrorsHandler } from '@helpers/utils'
import { useCreateReport, useEditReport, useGetReport } from '@queries/Reports'
import { useGetWorkflows } from '@queries/Workflows'
import { useGetRequestsFilters } from '@queries/Requests'

const Schema = Yup.object().shape({
  name: Yup.string().required(i18n.t('reports:form.fieldIsRequiredError')),
  workflow: Yup.number().required(i18n.t('reports:form.fieldIsRequiredError')),
  requestor: Yup.number().nullable(),
  approver: Yup.number().nullable(),
  status: Yup.string().nullable(),
})

const ReportForm = () => {
  const { t } = useTranslation('reports', { keyPrefix: 'form' })
  const { t: tCommon } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const { mutateAsync: createReport, isLoading: isCreating } = useCreateReport()
  const { mutateAsync: editReport, isLoading: isEditing } = useEditReport(id)
  const { data: report } = useGetReport(id || '')

  const { data: workflows } = useGetWorkflows()

  const { data: approvalSearch } = useGetRequestsFilters()

  const workflowOptions = useMemo<Option<number>[]>(
    () =>
      approvalSearch?.workflows?.map(({ id, name: wfName }) => {
        const workflow = workflows?.find((el) => el.id === id)
        const pipeName = workflow?.pipeline?.name
        const stageName = workflow?.pipeline?.stage_name

        let name = workflow?.name

        if (pipeName) {
          name = pipeName
          if (stageName) {
            name += `: ${stageName}`
          }
        }

        return { value: id, label: name || wfName }
      }) || [],
    [approvalSearch, workflows],
  )

  const requestorsOptions = useMemo(
    () =>
      (approvalSearch?.requestors || []).map(({ id, full_name: label }) => ({
        value: id,
        label,
      })),
    [approvalSearch, workflows],
  )
  const approversOptions = useMemo(
    () =>
      (approvalSearch?.approvers || []).map(({ id, full_name: label }) => ({
        value: id,
        label,
      })),

    [approvalSearch],
  )

  const isEdit = !!report

  const { values, handleChange, submitForm, errors } = useFormik({
    initialValues: {
      name: report?.name || '',
      workflow: report?.workflow?.id,
      requestor: report?.requestor,
      approver: report?.approver,
      status: report?.status,
    },
    validationSchema: Schema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const requestData = {
          name: values.name,
          workflow: values.workflow,
          requestor: values.requestor || null,
          approver: values.approver || null,
          status: values.status || null,
        }

        isEdit ? await editReport(requestData) : await createReport(requestData)
        isEdit ? toast.success(t('updatedToast', { name: report.name })) : toast.success(t('createdToast'))
        navigate(routes.private.reports)
      } catch (e) {
        requestErrorsHandler(e)
      }
    },
  })

  const statusOptions = useMemo<Option[]>(() => {
    return Object.entries(statuses)
      .filter((statusEntry) => statusEntry[0] !== Status.not_required)
      .map(([status, data]) => ({
        value: status,
        label: data.title,
      }))
  }, [])

  const handleGoBack = useCallback(() => navigate(routes.private.reports), [navigate])

  const isProcessing = isCreating || isEditing

  const buttons = (
    <div className="flex gap-1.5">
      <Button color="white" onClick={handleGoBack} disabled={isProcessing}>
        {tCommon('cancel')}
      </Button>
      <Button color="success" onClick={submitForm} loading={isProcessing}>
        {t('saveReport')}
      </Button>
    </div>
  )

  const title = useMemo(() => {
    return (
      <div className="flex items-center gap-1.5">
        <IconPencil className="text-gray-400 w-5 h-5" />
        <span>{isEdit ? report?.name : t('createTitle')}</span>
      </div>
    )
  }, [report])

  return (
    <>
      <Header title={title} backAction={handleGoBack} buttons={buttons} />
      <div className="base">
        <Input
          value={values.name}
          name="name"
          onChange={handleChange}
          label={t('labels.reportName')}
          placeholder={t('placeholders.reportName')}
          required
          errorMessage={errors.name}
        />
        <Select
          value={values.workflow}
          name="workflow"
          onChange={handleChange}
          label={t('labels.workflow')}
          required
          options={workflowOptions}
          errorMessage={errors.workflow}
        />

        <Select
          options={requestorsOptions}
          value={values.requestor}
          name="requestor"
          onChange={handleChange}
          placeholder={t('placeholders.select')}
          label={t('labels.requestor')}
          isClearable
        />
        <Select
          options={approversOptions}
          name="approver"
          value={values.approver}
          onChange={handleChange}
          placeholder={t('placeholders.select')}
          label={t('labels.approver')}
          isClearable
        />
        <Select
          value={values.status}
          name="status"
          onChange={handleChange}
          label={t('labels.status')}
          options={statusOptions}
          isClearable
          styles={{
            multiValue: (base: CSSObjectWithLabel) => ({
              ...base,
              backgroundColor: 'none',
              borderRadius: '0.5rem',
              padding: 0,
              paddingLeft: '0.75rem',
              margin: '0 0.25rem',
            }),
            multiValueRemove: (base: CSSObjectWithLabel) => ({
              ...base,
              border: 'none',
              borderRadius: '0.5rem',
              marginLeft: '0.125rem',
            }),
            multiValueLabel: (base: CSSObjectWithLabel) => ({
              ...base,
              border: 'none',
              fontSize: '12px',
              padding: '0.125rem 0',
              color: 'inherit',
            }),
            clearIndicator: (base: CSSObjectWithLabel) => ({
              ...base,
              stroke: 'red',
              strokeWidth: 10,
            }),
          }}
          components={{
            MultiValue: (props) => {
              const status = statuses[props.data.value as Status] || {}
              return (
                <components.MultiValue
                  {...props}
                  className={cx(status.textColor, status.bgColor, status.borderColor, 'border')}
                >
                  {props.children}
                </components.MultiValue>
              )
            },
            ClearIndicator: (props) => {
              return (
                <components.ClearIndicator {...props}>
                  <IconX
                    className="text-neutral-300 hover:stroke-black hover:opacity-60 transition w-4 h-4"
                    stroke={4}
                  />
                </components.ClearIndicator>
              )
            },
          }}
        />
      </div>
    </>
  )
}

export default ReportForm
