import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { toast } from '@src/helpers/toaster'

import { useGetMyAccount } from '@queries/Account'
import { useAcceptInvite, useGetInviteData } from '@queries/Teams'
import routes from '@src/Routes/routes'
import { requestErrorsHandler } from '../utils'

const Auth = (Cmp) => {
  const Authenticated = (props) => {
    const { data: user, isInitialLoading, isSuccess: isLoggedIn, refetch: getAccount } = useGetMyAccount()
    const { mutateAsync: acceptInvite } = useAcceptInvite()
    const onboarded = !!user?.team?.onboarded
    const navigate = useNavigate()

    const { search, state, pathname } = useLocation()

    const query = new URLSearchParams(search)
    const invite = query.get('invite')

    const { isFetched: isInviteFetched, error: inviteCodeError } = useGetInviteData()

    const handleInviteCheck = async () => {
      try {
        if (isInviteFetched && !inviteCodeError) {
          await acceptInvite({ invite })
          navigate(pathname, { replace: true })
          toast.success('You successfully joined to the Team')
          await getAccount()
        } else {
          if (inviteCodeError?.response?.status === 404) {
            toast.error('Invite code does not exist')
          } else {
            requestErrorsHandler(inviteCodeError)
          }
          navigate(pathname)
        }
      } catch (err) {
        if (err?.response?.status !== 500) {
          requestErrorsHandler(err)
        }
      }
    }

    useEffect(() => {
      if (!!user && invite) {
        handleInviteCheck()
      }
    }, [user, isInviteFetched, inviteCodeError])

    if (isLoggedIn) {
      if (!onboarded && !pathname.startsWith('/onboarding')) {
        return (
          <Navigate
            to={{
              pathname: '/onboarding',
              state,
            }}
          />
        )
      }

      return <Cmp {...props} />
    }

    if (!isLoggedIn && !isInitialLoading) {
      return (
        <Navigate
          to={{
            pathname: invite ? routes.public.signup : routes.public.login,
            search,
            state: { ...(state || {}), prev: pathname },
          }}
        />
      )
    }

    return null
  }

  return Authenticated
}

export default Auth
