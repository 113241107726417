import React, { useCallback, useEffect, useMemo } from 'react'
import { SingleValue, components } from 'react-select'
import cx from 'clsx'

import Select, { useSelectHelpers } from '@components/Form/ReactSelect'

import { useGetAccountCategories } from '@queries/Accounting/accounts'
import { useCostCenterContext } from '@src/pages/Accounting/CostCenter/Form/utils'
import { getYear } from 'date-fns'

export interface CategoryProps {
  value: number | undefined
  year?: number
}

export const Category: React.FC<CategoryProps> = ({ value, year }) => {
  const { values, setValues } = useCostCenterContext()

  const { data: categories } = useGetAccountCategories()

  const options = useMemo(() => {
    return (
      categories
        ?.filter((cat) => {
          const isSameCategoryExist = values.budgetAllocations.some((budget) => {
            const isSameYear = year ? getYear(budget.startDate) === year : true
            const isCategoryUsed = budget.category === cat.id
            return isSameYear && isCategoryUsed
          })
          const isCurrentCategory = value === cat.id
          return !isSameCategoryExist || isCurrentCategory
        })
        ?.map(({ id: value, name: label }) => ({ label, value })) || []
    )
  }, [categories, values, value, year])

  const { optionValue } = useSelectHelpers(options, value)

  const handleChange = useCallback(
    (option: SingleValue<Option<number>>) => {
      setValues((values) => ({
        ...values,
        budgetAllocations: values.budgetAllocations.map((budget) => {
          if (
            value === budget.category &&
            !budget.isUncategorized &&
            (year ? getYear(budget.startDate) === year : true)
          ) {
            return { ...budget, category: option?.value }
          }
          return budget
        }),
      }))
    },
    [value],
  )

  useEffect(() => {
    if (!value) {
      handleChange(options[0])
    }
  }, [categories])

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={optionValue}
      menuPortalTarget={document.body}
      className={cx('min-w-[200px] !mb-0 group')}
      menuPlacement="auto"
      components={{
        IndicatorSeparator: () => null,
        Control: ({ children, ...props }) => (
          <components.Control
            {...props}
            className={cx('group-hover:!border-blue-500 !shadow-none !border-transparent')}
          >
            {children}
          </components.Control>
        ),
      }}
    />
  )
}

export default React.memo(Category)
