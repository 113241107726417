import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface APIProps {}

const HERO_IMAGE = '/paywalls/api/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:api.articles.setUp.title'),
    text: i18n.t('paywalls:api.articles.setUp.text'),
    image: '/paywalls/api/set-up.png',
  },
]

export const API: React.FC<APIProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'api' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section title={t('section.title')} text={t('section.text')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article, index) => (
          <Article
            key={article.title}
            title={article.title}
            image={article.image}
            text={article.text}
            hasActionButtons={index === ARTICLES.length - 1}
          />
        ))}
      </div>
    </>
  )
}

export default API
