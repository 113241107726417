import React from 'react'

import Select, { useSelectHelpers } from '@components/Form/ReactSelect'
import SpendingsDetails from '@pages/Accounting/CostCenter/Details/components/SpendingsDetails'
import TabNavigation from '@pages/Accounting/CostCenter/Details/components/TabNavigation'
import { SpendingCategoriesList } from '@pages/Accounting/CostCenter/Details/components/SpendingCategoriesList'

import useGetUUIDFromPath from '@helpers/hooks/useGetUUIDFromPath'
import { useGetCostCenterDetail } from '@queries/Accounting/costCenter'
import useStore from '@pages/Accounting/CostCenter/store'

const BudgetTab = () => {
  const { id } = useGetUUIDFromPath()
  const { data: costCenterDetail } = useGetCostCenterDetail(id)

  const setFilter = useStore.use.setFilter()
  const filters = useStore.use.filters()
  const periodOptions = useStore.use.periodOptions()

  const { optionValue, handleChange } = useSelectHelpers(periodOptions, filters.period, (value?: string) =>
    setFilter('period', value),
  )

  return (
    <div className=" flex flex-col gap-3">
      <div className="flex justify-between align-center flex-wrap gap-1.5">
        <TabNavigation />
        {!!periodOptions.length && (
          <Select options={periodOptions} value={optionValue} onChange={handleChange} className="w-48 !mb-0" />
        )}
      </div>
      {costCenterDetail && <SpendingsDetails data={costCenterDetail} startDate={filters.period} />}
      {!!costCenterDetail?.budget_allocations?.length && (
        <SpendingCategoriesList data={costCenterDetail.budget_allocations} startDate={filters.period} />
      )}
    </div>
  )
}
export default BudgetTab
