import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from '@components/Button'
import { IconArrowRight } from '@tabler/icons-react'

import routes from '@src/Routes/routes'

const NotConnectedBadge = () => {
  const { t } = useTranslation('reports', { keyPrefix: 'ai' })
  const navigate = useNavigate()

  const handleButtonClick = useCallback(() => {
    navigate(generatePath(routes.private.settings, { tab: 'integration' }) + '?integration=ai')
  }, [])

  return (
    <div className="flex items-center justify-center text-center absolute rounded-xl bg-magnolia px-3 gap-3 py-0.5 flex-wrap mt-2 z-[2]">
      <p className="text-brand_primary font-medium">{t('notConnectedTitle')}</p>
      <Button onClick={handleButtonClick} className="mi-h-0 h-6 rounded-full">
        <span>{t('connect')}</span>
        <IconArrowRight />
      </Button>
    </div>
  )
}

export default NotConnectedBadge
