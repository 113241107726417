import React from 'react'
import { format } from 'date-fns'

import { useCostCenterContext } from '../../../utils'
import { val2Amount } from '@helpers/utils'

export interface AmountProps {
  startDate?: Date
  currencySymbol?: string
}

export const Amount: React.FC<AmountProps> = ({ currencySymbol, startDate }) => {
  const { values } = useCostCenterContext()

  const amount = values.budgetAllocations.reduce((sum, budget) => {
    if (format(budget.startDate, 'yyyy-MM-dd') !== format(startDate || new Date(), 'yyyy-MM-dd')) {
      return sum
    }

    const currentSum = sum * 100
    const currentBudget = (budget.amount || 0) * 100
    const newSum = budget.isUncategorized ? currentSum + currentBudget : currentSum - currentBudget

    return newSum / 100
  }, 0)

  return (
    <>
      {currencySymbol}
      {val2Amount(amount * 100)}
    </>
  )
}

export default React.memo(Amount)
