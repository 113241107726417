import React, { useCallback, useEffect, useMemo } from 'react'
import cx from 'clsx'
import { NavLink, generatePath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import ContentLock from '@components/ContentLock'
import Tooltip from '@components/Tooltip'
import OrganizationMenu from '../OrganizationMenu'
import Divider from '@components/Divider'

import { useGetMyAccount } from '@queries/Account'
import { useIsMobile } from '@src/helpers'
import useWindowSize from '@helpers/hooks/useWindowSize'

import { AP_ITEMS, AR_ITEMS, NAV_ITEMS, CONTRACT_ITEMS, NavItem } from '../menuItems'
import { filterNavItems, useWindowControlsOverlay } from '../utils'
import { useGetShowCustomers } from '@helpers/hooks/useGetShowCustomers'
import routes from '@src/Routes/routes'
import useStore from '@src/store'
import { useGetTeamSubscription } from '@queries/Billing'
import { useGetMaintenanceBanner } from '@helpers/hooks/maintenace/useGetMaintenanceBanner'
import { getPaywallPath, useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'
import { SHOW_MAINTENANCE_BANNER } from '@src/constants'

import { ContactParty } from '@models/ApproveitAccounting'

import styles from './index.module.css'
import { Feature } from '@src/types/features'

interface NavigationProps {
  children?: React.ReactNode
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  className?: string
}

const NavigationBlock = ({
  items,
  isCollapsed,
  title,
  className,
  feature,
}: {
  items: NavItem[]
  isCollapsed: boolean
  title?: string
  className?: string
  feature?: Feature
}) => {
  const { available, paywallPath } = useGetIsFeatureAvailable(feature)
  return items.length ? (
    <div className={twMerge(cx('pt-3 flex flex-col text-nowrap', className))}>
      {title && (
        <div
          className={cx(
            'flex justify-between items-center pl-5 pr-3 text-sm font-medium text-grey_1 transition-opacity duration-400 mb-1',
            isCollapsed ? 'opacity-0' : 'opacity-100',
          )}
        >
          {title}
          {!available && paywallPath && <ContentLock to={paywallPath} />}
        </div>
      )}
      {items.map((item) => (
        <NavigationLink key={item.to} {...item} disabled={!available} isCollapsed={isCollapsed} />
      ))}
    </div>
  ) : null
}

const NavigationLink: React.FC<NavItem> = ({ to, disabled, icon: Icon, label, feature, isCollapsed }) => {
  const { data: user } = useGetMyAccount()
  const onboarded = user?.team?.onboarded
  const { available, paywall } = useGetIsFeatureAvailable(feature)

  if (!to) return null
  const isDisabled = !available || disabled || !onboarded
  return (
    <>
      <NavLink
        to={!available && paywall ? getPaywallPath(paywall) : to}
        className={({ isActive }) =>
          cx(
            (isActive || (window.location.pathname === '/' && to === '/dashboard')) && styles.active,
            'flex items-center px-2 group',
            isDisabled && styles.disabled,
          )
        }
        onContextMenu={(e) => e.stopPropagation()}
        data-tooltip-content={isCollapsed ? label : ''}
        data-tooltip-id={to}
      >
        <div
          className={cx(
            styles.leftNavLink,
            isCollapsed && styles.leftNavLinkCollapsed,
            !isDisabled &&
              'group-hover:bg-soft_lavender group-active:outline group-active:outline-blue_lavender group-active:bg-blue_lavender',
          )}
        >
          <Icon
            className={cx(
              styles.navIcon,
              !isDisabled && 'group-hover:scale-105 group-active:scale-95',
              isDisabled && 'opacity-60',
            )}
            stroke={1.5}
          />
          <div className="flex w-full justify-between">
            <span className={cx(isDisabled && 'opacity-60')}>{label}</span>
          </div>
          {!available && <ContentLock />}
        </div>
      </NavLink>
      {isCollapsed && <Tooltip id={to} place="right" offset={10} />}
    </>
  )
}

const BURGER_BREAKPOINT = 768

const LeftNav: React.FC<NavigationProps> = ({ isCollapsed = true, setIsCollapsed, children, className }) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })

  const isSettingsPage = useLocation().pathname.includes('/settings')
  const hoverTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)

  const isMobile = useIsMobile()
  const { width } = useWindowSize()
  const isWindowControlsOverlay = useWindowControlsOverlay()
  const [maintenace] = useGetMaintenanceBanner()

  const { data: user } = useGetMyAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })

  const showCustomers = useGetShowCustomers()
  const isOnboarding = useStore.use.isOnboarding()

  const onboarded = user?.team?.onboarded

  const handleMouseEnter = useCallback(() => {
    if (isWindowControlsOverlay) {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current)
      }
      hoverTimeoutRef.current = setTimeout(() => {
        setIsCollapsed(false)
      }, 333)
    }
  }, [isWindowControlsOverlay])

  const handleMouseLeave = useCallback(() => {
    if (isWindowControlsOverlay) {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current)
      }
      hoverTimeoutRef.current = setTimeout(() => {
        setIsCollapsed(true)
      }, 100)
    }
  }, [isWindowControlsOverlay])

  useEffect(() => {
    if (isWindowControlsOverlay) {
      setIsCollapsed(true)
    }
  }, [isWindowControlsOverlay])

  const commonItems = useMemo(() => {
    return user ? filterNavItems(NAV_ITEMS, user) : []
  }, [user])
  const apItems = useMemo(() => {
    return user
      ? filterNavItems(AP_ITEMS, user, {
          [generatePath(routes.private.contacts, { contactParty: ContactParty.customer })]: showCustomers,
        })
      : []
  }, [user])
  const arItems = useMemo(() => {
    return user
      ? filterNavItems(AR_ITEMS, user, {
          [generatePath(routes.private.contacts, { contactParty: ContactParty.vendor })]: showCustomers,
        })
      : []
  }, [user])
  const contractItems = useMemo(() => {
    return user ? filterNavItems(CONTRACT_ITEMS, user) : []
  }, [user])

  const hasBanner = subscription?.is_trial || (!subscription?.is_trial && SHOW_MAINTENANCE_BANNER && maintenace.show)

  return (
    <>
      {onboarded && (
        <div
          className={cx(
            className,
            styles.leftNav,
            isCollapsed ? styles.collapsed : styles.expanded,
            (isMobile || width < BURGER_BREAKPOINT) && !isWindowControlsOverlay ? 'hidden' : 'flex',
            isSettingsPage && isCollapsed && 'w-0 px-0 transition-all',
          )}
          onContextMenu={(e) => e.preventDefault()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            height: isWindowControlsOverlay
              ? 'calc(100vh - env(titlebar-area-height, 56px))'
              : `calc(100vh - ${!isOnboarding && hasBanner ? 104 : 56}px)`,
          }}
        >
          <OrganizationMenu classes={{ companyItem: cx(isCollapsed && 'ml-10') }} />
          <div className="w-full px-4">
            <Divider className="!my-1.5 px-2" />
          </div>
          <NavigationBlock items={commonItems} isCollapsed={isCollapsed} className="pt-0" />
          <NavigationBlock
            items={apItems}
            feature={Feature.accountsPayable}
            isCollapsed={isCollapsed}
            title={t('accountsPayable')}
          />
          <NavigationBlock
            items={arItems}
            feature={Feature.accountsReceivable}
            isCollapsed={isCollapsed}
            title={t('accountsReceivable')}
          />
          <NavigationBlock items={contractItems} isCollapsed={isCollapsed} title={t('contractsManagement')} />
        </div>
      )}
      {children}
    </>
  )
}
export default LeftNav
