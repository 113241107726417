import { useMemo, useState } from 'react'

const paginateList = <T = unknown>(records: T[], page: number, limit: number) => {
  return records.slice((page - 1) * limit, (page - 1) * limit + limit)
}

type UsePopupHandlersAttributes = {
  limit: number
}
export const useGetPagingHelpers = <T = unknown>(
  { limit }: UsePopupHandlersAttributes = { limit: 10 },
  pageOutside?: number,
  setPageOutside?: (page: number) => void,
) => {
  const [page, setPage] = useState(1)

  return useMemo(
    () => ({
      handlePageChange: setPageOutside || setPage,
      getPaginatedList: (records: T[]) => paginateList(records, pageOutside || page, limit),
      page: pageOutside || page,
      limit,
    }),
    [page, pageOutside, limit],
  )
}

export default useGetPagingHelpers
