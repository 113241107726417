import React, { useCallback } from 'react'
import { CellContext } from '@tanstack/react-table'

import { TrashIcon } from '@heroicons/react/outline'
import Button from '@components/Button'

import { useCostCenterContext } from '../../../utils'

import { BudgetAllocation } from '../../../types'

export const Actions: React.FC<CellContext<BudgetAllocation, unknown>> = ({ row }) => {
  const { setValues } = useCostCenterContext()
  const handleDeleteRow: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setValues((values) => {
      return {
        ...values,
        budgetAllocations: values.budgetAllocations.filter((budget) => budget.groupId !== row.original.groupId),
      }
    })
  }, [])
  return !row.original.isUncategorized ? (
    <Button type="contained" className="p-2 mx-auto" onClick={handleDeleteRow} buttonType="button">
      <TrashIcon className="h-4 w-4" />
    </Button>
  ) : null
}

export default React.memo(Actions)
