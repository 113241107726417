import cx from 'clsx'
import React from 'react'
import { twMerge } from 'tailwind-merge'

const Divider = ({ vertical = false, className = '', ...otherProps }) => {
  const baseStyle = vertical ? 'border-l h-full mx-3' : 'w-full border-b my-3'

  return <div className={twMerge(cx(baseStyle, className))} {...otherProps} />
}

export default Divider
