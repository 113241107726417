import React, { useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import CostCenters from './pages/CostCenters'
import Contracts from './pages/Contracts'
import Integrations from './pages/Integrations'
import AccountsPayable from './pages/AccountsPayable'
import AccountsReceivable from './pages/AccountsReceivable'
import Quickbooks from './pages/Quickbooks'
import Xero from './pages/Xero'
import Softledger from './pages/Softledger'
import Netsuite from './pages/Netsuite'
import Bamboo from './pages/Bamboo'
import GoogleCalendar from './pages/GoogleCalendar'
import AI from './pages/AI'
import API from './pages/API'
import Mailbox from './pages/Mailbox'

import { FeaturesPaywall, FeaturesToPaywall, useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'

import { Feature } from '@type/features'
import { useGetTeamSubscription } from '@src/api/queries/Billing'

export interface PaywallProps {}

const Pages = {
  [FeaturesPaywall.CostCenters]: CostCenters,
  [FeaturesPaywall.Contracts]: Contracts,
  [FeaturesPaywall.Integrations]: Integrations,
  [FeaturesPaywall.AccountsPayable]: AccountsPayable,
  [FeaturesPaywall.AccountsReceivable]: AccountsReceivable,
  [FeaturesPaywall.Quickbooks]: Quickbooks,
  [FeaturesPaywall.Xero]: Xero,
  [FeaturesPaywall.Softledger]: Softledger,
  [FeaturesPaywall.Netsuite]: Netsuite,
  [FeaturesPaywall.Bamboo]: Bamboo,
  [FeaturesPaywall.GoogleCalendar]: GoogleCalendar,
  [FeaturesPaywall.AI]: AI,
  [FeaturesPaywall.API]: API,
  [FeaturesPaywall.Mailbox]: Mailbox,
}

export const Paywall: React.FC<PaywallProps> = () => {
  const { feature: paywallFeature } = useParams<{ feature: FeaturesPaywall }>()
  const { data } = useGetTeamSubscription()

  const feature = useMemo(() => {
    if (
      paywallFeature &&
      [FeaturesPaywall.Xero, FeaturesPaywall.Quickbooks, FeaturesPaywall.Softledger].includes(paywallFeature)
    ) {
      return Feature.accountingIntegrations
    }
    return Object.entries(FeaturesToPaywall).find(([_, paywall]) => paywall === paywallFeature)?.[0] as Feature
  }, [paywallFeature])

  const { available } = useGetIsFeatureAvailable(feature)

  if (available && !!data?.plan_features) {
    return <Navigate to="/" />
  }

  const Component = paywallFeature ? Pages[paywallFeature] : null

  return <div className="max-w-5xl mx-auto py-10">{Component ? <Component /> : null}</div>
}

export default Paywall
