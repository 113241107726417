export enum Feature {
  accountsPayable = 'acc_payable',
  accountsReceivable = 'acc_receivable',
  accountingIntegrations = 'accounting_integrations',
  mailbox = 'mailbox',
  zapier = 'zapier',
  contractManagement = 'contract_management',
  costCenters = 'cost_centers',
  aiReports = 'ai_reports',
  googleCalendar = 'google_calendar_integration',
  netsuite = 'netsuite_integration',
  api = 'api',
  eSignature = 'e_signature',
  accessManagement = 'access_management',
  integrations = 'integrations',
  bambooHR = 'bamboo_hr_integration',
}
