import { val2Amount } from '@src/helpers/utils'

type AmountValue = number | null
export const getAmounts = (
  allocatedAmount: AmountValue,
  completedAmount: AmountValue,
  commitedAmount: AmountValue,
  currency?: string,
) => {
  const allocated = allocatedAmount || 0
  const spent = completedAmount || 0
  const commited = commitedAmount || 0

  const remining = allocated - spent - commited
  const isOverspend = remining < 0

  return {
    isOverspend: remining < 0,
    allocated: val2Amount(allocated, currency),
    spent: val2Amount(spent, currency),
    commited: val2Amount(commited, currency),
    remaining: val2Amount(remining, currency),
    overspent: val2Amount(isOverspend ? remining * -1 : 0, currency),
  }
}
