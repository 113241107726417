import { OnboardingStep, OnboardingType } from '@type/onboarding'

import i18n from '@src/i18n'

const Step = {
  [OnboardingStep.welcome]: {
    step: OnboardingStep.welcome,
    label: i18n.t('onboarding:progressSteps.welcome'),
  },
  [OnboardingStep.workflow]: {
    step: OnboardingStep.workflow,
    label: i18n.t('onboarding:progressSteps.workflow'),
  },
  [OnboardingStep.request]: {
    step: OnboardingStep.request,
    label: i18n.t('onboarding:progressSteps.request'),
  },
  [OnboardingStep.bill]: {
    step: OnboardingStep.bill,
    label: i18n.t('onboarding:progressSteps.bill'),
  },
  [OnboardingStep.po]: {
    step: OnboardingStep.po,
    label: i18n.t('onboarding:progressSteps.po'),
  },
  [OnboardingStep.invoice]: {
    step: OnboardingStep.invoice,
    label: i18n.t('onboarding:progressSteps.invoice'),
  },
  [OnboardingStep.contract]: {
    step: OnboardingStep.contract,
    label: i18n.t('onboarding:progressSteps.contract'),
  },
  [OnboardingStep.vendor]: {
    step: OnboardingStep.vendor,
    label: i18n.t('onboarding:progressSteps.vendor'),
  },
  [OnboardingStep.customer]: {
    step: OnboardingStep.customer,
    label: i18n.t('onboarding:progressSteps.customer'),
  },
  [OnboardingStep.team]: {
    step: OnboardingStep.team,
    label: i18n.t('onboarding:progressSteps.team'),
  },
  [OnboardingStep.upgrade]: {
    step: OnboardingStep.upgrade,
    label: i18n.t('onboarding:progressSteps.upgrade'),
  },
}

export const OnboardingSteps = {
  [OnboardingType.bill]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.bill],
    Step[OnboardingStep.vendor],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.po]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.po],
    Step[OnboardingStep.vendor],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.invoice]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.invoice],
    Step[OnboardingStep.customer],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.contract]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.contract],
    Step[OnboardingStep.vendor],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.access]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.vendor]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.vendor],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.project]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
  [OnboardingType.timeOff]: [
    Step[OnboardingStep.welcome],
    Step[OnboardingStep.workflow],
    Step[OnboardingStep.request],
    Step[OnboardingStep.team],
    Step[OnboardingStep.upgrade],
  ],
}
