import React from 'react'
import { useTranslation } from 'react-i18next'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface ContractsProps {}

const HERO_IMAGE = '/paywalls/contracts/hero.png'
const ARTICLE_VENDOR_IMAGE = '/paywalls/contracts/vendor.png'
const ARTICLE_NOTIFICATIONS_IMAGE = '/paywalls/contracts/notifications.png'
const ARTICLE_APPROVAL_IMAGE = '/paywalls/contracts/approval.png'

export const Contracts: React.FC<ContractsProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'contracts' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section tip={t('section.tip')} title={t('section.title')} />
      <div className="flex flex-col gap-6">
        <Article title={t('articles.vendor.title')} image={ARTICLE_VENDOR_IMAGE} text={t('articles.vendor.text')} />
        <Article
          title={t('articles.notifications.title')}
          image={ARTICLE_NOTIFICATIONS_IMAGE}
          text={t('articles.notifications.text')}
        />
        <Article
          title={t('articles.approval.title')}
          image={ARTICLE_APPROVAL_IMAGE}
          text={t('articles.approval.text')}
          hasActionButtons
        />
      </div>
    </>
  )
}

export default Contracts
