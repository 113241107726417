import React, { useEffect, useId, useRef } from 'react'
import cx from 'clsx'

import { getInitials } from '@helpers/utils'
import Tooltip from '../Tooltip'

const CompanyItem = ({
  orgName,
  className,
  labelClassName,
  initialsStyles,
  wrapperClassName,
}: {
  orgName?: string
  className?: string
  labelClassName?: string
  initialsStyles?: React.CSSProperties
  wrapperClassName?: string
}) => {
  const tooltipId = useId()
  const ref = useRef<HTMLDivElement>(null)

  const [isNameTruncated, setIsNameTruncated] = React.useState(false)
  useEffect(() => {
    if (ref.current && orgName) {
      setIsNameTruncated((ref.current?.scrollWidth || 0) > (ref.current?.offsetWidth || 0))
    }
  }, [orgName])

  return (
    <div className={cx('flex gap-2 justify-start items-center', className)}>
      <div
        className={cx(
          'text-white bg-blue-500 rounded-base w-8 h-8 flex items-center justify-center group-hover:scale-105 group-active:scale-95',
          wrapperClassName,
        )}
        style={initialsStyles}
      >
        {getInitials(orgName)}
      </div>
      <div data-tooltip-id={tooltipId} className={cx('text-start w-32 truncate', labelClassName)} ref={ref}>
        {orgName}
      </div>
      {isNameTruncated && <Tooltip content={orgName} offset={10} id={tooltipId} place="bottom" />}
    </div>
  )
}

export default CompanyItem
