import React from 'react'
import cx from 'clsx'
import { endOfYear, format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IconBeach } from '@tabler/icons-react'
import ProgressBar from '@components/ProgressBar'

import { useGetMyAccount } from '@queries/Account'

import { TimeOffDuration } from '@type/common'

const TimeOffLimits = () => {
  const { data: user } = useGetMyAccount()
  const { limit = 0, duration = 0, type = TimeOffDuration.day } = user?.time_off || {}
  const { t } = useTranslation('settings', { keyPrefix: 'profile.timeOff' })

  const tillDate = format(endOfYear(new Date()), 'MMM, dd, yyyy')
  const exceeds = duration > limit
  const exceedsBy = duration - limit

  const progressValue = exceeds ? 100 : Math.ceil((duration / limit) * 100)

  const remaining = exceeds ? 0 : limit - duration

  return (
    <div className="mb-3 border rounded-xl w-full px-3 py-4 flex flex-col gap-2">
      <div className="flex justify-between w-full">
        <div className="flex gap-1.5 items-center">
          <div className="w-6 h-6 p-1 bg-blue-50 rounded-md">
            <IconBeach className="text-gray-500 w-full h-full" />
          </div>
          <span className="text-lg">{t('title')}</span>
        </div>
        <div className="text-xs">
          <p
            className={cx('font-medium', {
              'text-emerald-500': !exceeds,
              'text-red-500 font-medium': exceeds,
            })}
          >
            {exceeds
              ? t('exceeded', {
                  exceeded: exceedsBy,
                  units: t(type, { count: exceedsBy }),
                })
              : t('remaining', { value: remaining })}
          </p>
          <p className="text-gray-400">{t('till', { value: tillDate })}</p>
        </div>
      </div>
      <ProgressBar
        containerStyles="border-gray-200 bg-gray-200 h-2"
        barStyles="bg-brand_primary p-0"
        hideNumbers
        completedValue={progressValue}
      />
      <div className="flex justify-center">
        <p className="text-gray-500">
          {t('taken')}:
          <span className={cx({ 'text-red-500 font-medium': exceeds })}>
            {` ${duration}/${limit}`} {t(type, { count: duration })}
          </span>
        </p>
      </div>
    </div>
  )
}

export default TimeOffLimits
