import React from 'react'

import ActionButtons from '../ActionButtons'
import SuspenseImage from '@components/SuspenseImage'

export interface SectionProps {
  tip?: string
  title: string
  text?: string
  image?: string
  hasActionButtons?: boolean
}

export const Section: React.FC<SectionProps> = ({ tip, text, title, image, hasActionButtons = false }) => {
  return (
    <div className="flex flex-col gap-1 text-center mb-8">
      {tip && <p className="text-gray-500 uppercase text-lg">{tip}</p>}
      <p className="text-[20px] sm:text-5xl font-bold">{title}</p>
      {text && <p className="text-lg">{text}</p>}
      <div className="flex flex-col items-center gap-3">
        {image && (
          <div className="max-w-3xl mx-auto">
            <SuspenseImage src={image} alt={title} />
          </div>
        )}
        {hasActionButtons && <ActionButtons className="w-full sm:w-auto" />}
      </div>
    </div>
  )
}

export default Section
