import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePopupHandlers } from '@components/Popup/usePopupHandler'
import useGetAccount from '@helpers/hooks/useGetAccount'

import routes from '@src/Routes/routes'
import { Plan } from '@src/types/common'

export const useAddRequestClick = (requestsCount?: number) => {
  const navigate = useNavigate()
  const user = useGetAccount()
  const { handleTogglePopup, isPopupOpen } = usePopupHandlers()

  const hasUnlimitedRequests =
    user?.team?.subscription.plan === Plan.enterprise || user?.team?.subscription?.subscription_requests === -1

  const handleAddRequestClick = useCallback(() => {
    if (hasUnlimitedRequests || (user?.team?.subscription?.subscription_requests || 0) > (requestsCount || 0)) {
      navigate(routes.private.approvalRequest, {
        state: {
          backRoute: location.pathname,
        },
      })
    } else {
      handleTogglePopup()
    }
  }, [requestsCount, user?.team])

  return { handleAddRequestClick, handleTogglePopup, isPopupOpen }
}

export default useAddRequestClick
