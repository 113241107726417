import React, { useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'
import { IconSparkles, IconSquareRoundedPlus } from '@tabler/icons-react'

import Button from '@src/components/Button'

import routes from '@src/Routes/routes'
import { useGetShowAIReports } from '@helpers/hooks/useGetShowAIReports'
import { useIsAdmin } from '@src/helpers'

import styles from '../index.module.css'

const TabNavigation = () => {
  const { t } = useTranslation('reports', { keyPrefix: 'list' })
  const { t: tCommon } = useTranslation('common')
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()
  const showReports = useGetShowAIReports()
  const handleCreateReport = useCallback(() => navigate(routes.private.createReport), [])

  return (
    <div className="justify-between flex flex-wrap gap-2">
      <span className="flex text-xl">
        <NavLink
          className={({ isActive }) => {
            return cx(styles.tab, { [styles.active]: isActive })
          }}
          end
          to={routes.private.reports}
        >
          {t('tabs.manual')}
        </NavLink>
        {showReports && isAdmin && (
          <NavLink
            className={({ isActive }) => {
              return cx(styles.tab, 'flex gap-2', { [styles.active]: isActive })
            }}
            to={routes.private.aiReports}
          >
            <span>{t('tabs.ai')}</span>
            <div className="flex items-center text-sm  text-brand_primary bg-purple-50 border border-purple-200 rounded-lg px-2.5 h-6 w-16 mt-0.5">
              <IconSparkles className="w-3.5 h-3.5" />
              <span>{tCommon('new')}</span>
            </div>
          </NavLink>
        )}
      </span>
      <Button
        color="primary"
        className="text-base font-medium mb-2 gap-1 w-full xs:w-auto"
        onClick={handleCreateReport}
      >
        <IconSquareRoundedPlus size={20} />
        {t('newReport')}
      </Button>
    </div>
  )
}

export default TabNavigation
