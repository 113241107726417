import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@components/Button'
import Popup from '@components/Popup'

import { useChangeTeam, useGetMyAccount, useUpdateTeamInfo } from '@queries/Account'

import routes from '@src/Routes/routes'

import { parseJWT, requestErrorsHandler } from '@helpers/utils'

import styles from './index.module.css'

export interface SlckConnectionPopupProps {}

export const SlckConnectionPopup: React.FC<SlckConnectionPopupProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'slackConnection' })
  const { search } = useLocation()
  const navigate = useNavigate()

  const { data: account, refetch: getAccount, isLoading: isAccountLoading } = useGetMyAccount()
  const { mutateAsync: changeTeamInfo, isLoading: isTeamUpdateLoading } = useUpdateTeamInfo()

  const query = new URLSearchParams(search)

  const slackTeamName = query.get('slack')
  const token = query.get('token')
  const isConnected = !!parseInt(query.get('connected') || '')

  const { admin_email, admin_name } = parseJWT<{
    admin_email?: string
    admin_name?: string
  }>(token || '')

  const { mutateAsync: switchTeam, isLoading: isSwitchTeamLoading } = useChangeTeam()

  const handleClickLeft = useCallback(async () => {
    try {
      if (!isConnected && token) {
        await switchTeam({ token })
      }
      await getAccount()
      navigate(routes.private.dashboard)
    } catch (err) {
      requestErrorsHandler(err)
    }
  }, [])

  const handleClickRight = useCallback(async () => {
    try {
      if (isConnected && slackTeamName) {
        await changeTeamInfo({
          name: slackTeamName,
        })
        await getAccount()
      }
      navigate(routes.private.dashboard)
    } catch (err) {
      requestErrorsHandler(err)
    }
  }, [])

  const isLoading = isAccountLoading || isTeamUpdateLoading || isSwitchTeamLoading

  return slackTeamName ? (
    <Popup containerStyle={styles.popupContainer} className="bg-alice_blue rounded-base">
      <div className="flex flex-col items-center gap-5 text-center">
        {isConnected && (
          <>
            <div>
              <p className="text-xl">
                {t('teamNameTitle')} <b>{account?.team?.name}</b>
              </p>
              <p className="text-xl">
                {t('slackNameTitle')} <b>{slackTeamName}</b>
              </p>
            </div>
            <p>{t('teamRenameSubtitle')}</p>
          </>
        )}
        {!isConnected && (
          <>
            <p className="text-xl font-medium">{t('connectToTeamTitle')}</p>
            <p>
              {t('connectToTeamSubtitle')} {admin_name}({admin_email})
            </p>
          </>
        )}
        <div className="flex gap-10">
          <Button loading={isLoading} onClick={handleClickLeft}>
            {isConnected ? account?.team?.name : t('yesLabel')}
          </Button>
          <Button loading={isLoading} type="outlined" onClick={handleClickRight}>
            {isConnected ? slackTeamName : t('noLabel')}
          </Button>
        </div>
      </div>
    </Popup>
  ) : null
}

export default React.memo(SlckConnectionPopup)
