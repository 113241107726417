import React, { useCallback, useEffect, useState } from 'react'
import cx from 'clsx'

import { MenuDivider, MenuItem } from '@szhsin/react-menu'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Popup from '@components/Popup'

import { useGetMyAccount, useLogOut } from '@src/api/queries/Account'
import { useIsAdmin } from '@src/helpers'
import { useGetConnectedChannel } from '@src/helpers/hooks/useGetConnectedChannel'
import { useQueryClient } from '@tanstack/react-query'
import { useHandleClickVendors } from '@src/helpers/hooks/useHandleClickVendors'
import { useTranslation } from 'react-i18next'

import routes from '@src/Routes/routes'
import { SLACK_BOT } from '@src/constants'

import styles from '../index.module.css'
import { IconSettings, IconRocket, IconLogout, IconUser } from '@tabler/icons-react'
import Profile from '@src/pages/Settings/Profile'

interface AccountMenuProps {
  className?: string
}

const AccountMenu: React.FC<AccountMenuProps> = ({ className }) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })
  const { data: user } = useGetMyAccount()
  const { mutateAsync: logOut } = useLogOut()
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()
  const connectedChannel = useGetConnectedChannel()
  const hasAnyAppConnected = !!connectedChannel
  const { handleClickVendor } = useHandleClickVendors()
  const [profileSettingsOpen, setProfileSettingsOpen] = useState(false)
  const location = useLocation()
  const [isSettingsPage, setIsSettingsPage] = useState(false)

  const signOut = useCallback(async () => {
    await logOut({})
    navigate(routes.public.login)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const isSettingsPage = location.pathname === `${routes.private.settings}/profile`
      setIsSettingsPage(isSettingsPage)
    }, 1000)
  }, [location])

  return (
    <div className={className}>
      {profileSettingsOpen && (
        <Popup
          onClose={() => setProfileSettingsOpen(false)}
          className="bg-[linear-gradient(to_bottom,_rgb(245,245,245)_4.6rem,_white_4.6rem)]"
        >
          <Profile
            profileSettingsOpen={profileSettingsOpen}
            closeProfileSettings={() => setProfileSettingsOpen(false)}
          />
        </Popup>
      )}
      <div className="px-4 pt-0.5 flex flex-col">
        <p className="leading-5 pb-0.5 text-sm font-bold text-grey_1">{t('account')}</p>
        <MenuItem className={styles.menuItem}>
          <NavLink className="w-full leading-6 text-base font-medium text-black truncate" to={routes.private.settings}>
            {user?.team?.name}
          </NavLink>
        </MenuItem>
        <MenuItem className={styles.menuItem}>
          <NavLink className="w-full leading-6 text-base text-grey_1 truncate" to={routes.private.settings}>
            {user?.email}
          </NavLink>
        </MenuItem>
      </div>
      {isAdmin && !hasAnyAppConnected && (
        <>
          <MenuDivider className="!border-gray-100" />
          <div className="flex flex-col">
            <MenuItem className={styles.menuItem}>
              <NavLink
                className={cx(styles.navLinkButton, 'group text-jungle_green text-base w-full')}
                to={SLACK_BOT.link as string}
                onClick={handleClickVendor}
                data-vendor="Slack"
                data-qa="slack-connect-link"
              >
                <img src={SLACK_BOT.img} className={cx(styles.navIcon, 'group-hover:scale-105')} />
                {t('addToSlack')}
              </NavLink>
            </MenuItem>
            <MenuItem className={styles.menuItem}>
              <NavLink
                className={cx(styles.navLinkButton, 'group text-jungle_green text-base w-full')}
                to={routes.private.installTeams}
                data-qa="teams-connect-link"
                onClick={handleClickVendor}
                data-vendor="Teams"
              >
                <img src="/teams.png" className={cx(styles.navIcon, 'group-hover:scale-105')} />
                {t('addToTeams')}
              </NavLink>
            </MenuItem>
          </div>
        </>
      )}
      <MenuDivider className="!border-gray-100" />
      <div className="flex flex-col">
        {!isSettingsPage && (
          <MenuItem className={cx(styles.menuItem, 'group')}>
            <div
              className={cx(styles.navLinkButton, '!py-1 !px-2  !mx-2 text-base w-full')}
              onClick={() => setProfileSettingsOpen(true)}
            >
              <IconUser className={cx(styles.navIcon, 'group-hover:scale-105')} />
              {t('profile')}
            </div>
          </MenuItem>
        )}
        <MenuItem className={cx(styles.menuItem, 'group')}>
          <NavLink
            className={cx(styles.navLinkButton, '!py-1 !px-2  !mx-2 text-base w-full')}
            to={routes.private.settings}
            onContextMenu={(e) => e.stopPropagation()}
          >
            <IconSettings className={cx(styles.navIcon, 'group-hover:scale-105')} />
            {t('settings')}
          </NavLink>
        </MenuItem>
        {isAdmin && (
          <MenuItem className={cx(styles.menuItem, 'group')}>
            <NavLink
              className={cx(styles.navLinkButton, '!py-1 !px-2  !mx-2 text-base w-full')}
              to={routes.private.billing}
              onContextMenu={(e) => e.stopPropagation()}
            >
              <IconRocket className={cx(styles.navIcon, 'group-hover:scale-105')} />
              {t('subscription')}
            </NavLink>
          </MenuItem>
        )}
      </div>
      {isAdmin && <MenuDivider className="!border-gray-100" />}

      <div className="flex flex-col">
        <MenuItem className={cx(styles.menuItem, 'group')}>
          <button
            className={cx(styles.navLinkButton, '!py-1 !px-2 !my-0 !mx-2 text-base flex w-full leading-5')}
            onClick={signOut}
          >
            <IconLogout className={cx(styles.navIcon, 'ml-[1px] group-hover:scale-105')} />
            {t('logout')}
          </button>
        </MenuItem>
      </div>
    </div>
  )
}

export default AccountMenu
