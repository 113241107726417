import React from 'react'
import cx from 'clsx'

import { IconCheck } from '@tabler/icons-react'

import useStore from '@store'
import { OnboardingSteps } from './constants'

export interface OnboardingProgressProps {}

export const OnboardingProgress: React.FC<OnboardingProgressProps> = () => {
  const onboardingType = useStore.use.onboardingType()
  const step = useStore.use.onboardingStep()
  const steps = OnboardingSteps[onboardingType]
  const currentIndex = steps.findIndex((el) => el.step === step)

  return (
    <div className="hidden md:flex gap-3 mt-1">
      {steps.map((el, index) => (
        <React.Fragment key={el.step}>
          <div className="flex flex-col gap-[1px] items-center w-14">
            <div
              className={cx('w-5 h-5 rounded-full flex items-center', {
                'bg-blue-500': index <= currentIndex,
                'bg-gray-300': index > currentIndex,
              })}
            >
              {index > currentIndex - 1 && <div className={cx('w-[15px] h-[15px] m-auto rounded-full bg-white')} />}
              {index <= currentIndex - 1 && <IconCheck className="w-4 h-4 m-auto stroke-white" />}
            </div>
            <div className="text-sm text-center">{el.label}</div>
          </div>
          {index < steps.length - 1 && (
            <div
              className={cx(
                {
                  'bg-blue-500': index <= currentIndex - 1,
                  'bg-gray-300': index > currentIndex - 1,
                },
                'relative h-[3px] w-14 mt-2 -ml-6 -mr-6 rounded-base',
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OnboardingProgress
