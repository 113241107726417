import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { useGetTeamSubscription } from '@queries/Billing'

import routes from '@src/Routes/routes'
import { Feature } from '@type/features'
import { Integration } from '@type/integration'

export enum FeaturesPaywall {
  Contracts = 'contracts',
  CostCenters = 'cost-centers',
  Integrations = 'integrations',
  AccountsPayable = 'accounts-payable',
  AccountsReceivable = 'accounts-receivable',
  Xero = 'xero',
  Quickbooks = 'quickbooks',
  Softledger = 'softledger',
  Netsuite = 'netsuite',
  Bamboo = 'bamboo',
  GoogleCalendar = 'google-calendar',
  AI = 'ai',
  API = 'api',
  Mailbox = 'mailbox',
}

const AccountingIntegrationToPaywall: Partial<Record<Integration, FeaturesPaywall>> = {
  [Integration.xero]: FeaturesPaywall.Xero,
  [Integration.softledger]: FeaturesPaywall.Softledger,
  [Integration.quickbooks]: FeaturesPaywall.Quickbooks,
}

export const FeaturesToPaywall: Partial<Record<Feature, FeaturesPaywall>> = {
  [Feature.integrations]: FeaturesPaywall.Integrations,
  [Feature.accountsPayable]: FeaturesPaywall.AccountsPayable,
  [Feature.accountsReceivable]: FeaturesPaywall.AccountsReceivable,
  [Feature.accountingIntegrations]: FeaturesPaywall.Integrations,
  [Feature.netsuite]: FeaturesPaywall.Netsuite,
  [Feature.costCenters]: FeaturesPaywall.CostCenters,
  [Feature.googleCalendar]: FeaturesPaywall.GoogleCalendar,
  [Feature.bambooHR]: FeaturesPaywall.Bamboo,
  [Feature.aiReports]: FeaturesPaywall.AI,
  [Feature.api]: FeaturesPaywall.API,
  [Feature.mailbox]: FeaturesPaywall.Mailbox,
  [Feature.contractManagement]: FeaturesPaywall.Contracts,
  [Feature.zapier]: FeaturesPaywall.Integrations,
}

type FeatureWithPaywall = { hidden: boolean; paywall?: FeaturesPaywall; paywallPath?: string }

export const useGetHiddenWithPaywallFeatures = () => {
  const { data: subscription } = useGetTeamSubscription()

  return useMemo(() => {
    const defaultValues = Object.keys(Feature).reduce<Record<Feature, FeatureWithPaywall>>((acc, feature) => {
      return {
        ...acc,
        [feature as Feature]: { hidden: false },
      }
    }, {} as Record<Feature, FeatureWithPaywall>)

    return Object.entries(subscription?.plan_features || {}).reduce<Record<Feature, FeatureWithPaywall>>(
      (acc, [feature, available]) => {
        const paywall = !available ? FeaturesToPaywall[feature as Feature] : undefined
        return {
          ...acc,
          [feature]: {
            hidden: !available,
            paywall: paywall,
            paywallPath: paywall ? getPaywallPath(paywall) : undefined,
          },
        }
      },
      defaultValues,
    )
  }, [subscription?.plan_features])
}

export const useGetIsFeatureAvailable = (feature?: Feature, { integration }: { integration?: Integration } = {}) => {
  const { data: subscription } = useGetTeamSubscription()

  return useMemo(() => {
    if (feature === Feature.accountingIntegrations) {
      const available = subscription?.plan_features?.[feature] ?? true

      const paywall = integration ? AccountingIntegrationToPaywall[integration] : FeaturesPaywall.Integrations
      return {
        available,
        paywall: !available ? paywall : undefined,
        paywallPath: !available && paywall ? getPaywallPath(paywall) : undefined,
      }
    }
    const paywall = feature ? FeaturesToPaywall[feature] : undefined

    const available = feature ? subscription?.plan_features?.[feature] ?? true : true
    return {
      available,
      paywall: !available ? paywall : undefined,
      paywallPath: !available && paywall ? generatePath(routes.private.paywall, { feature: paywall }) : undefined,
    }
  }, [subscription])
}

export const getPaywallPath = (paywall: FeaturesPaywall) => {
  return generatePath(routes.private.paywall, { feature: paywall })
}
