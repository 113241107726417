import { useCreate } from '@queries/request'

import { ReportResponse, ReportsPayload } from '@models/DWH'

const PATHS = {
  reports: '/api/dwh/reports',
}

export const useGetReports = () => {
  return useCreate<ReportsPayload, ReportResponse>(PATHS.reports)
}
