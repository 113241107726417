import { RateType } from '@type/billing'
import { Plan } from './Account'
import { Feature } from '@src/types/features'

export interface CheckoutStripeRequest {
  product_price: string
}
export interface UpgradeStripeRequest {
  price: string
}

export const enum Status {
  trialing = 'trialing',
  active = 'active',
  cancelled = 'cancelled',
  inactive = 'inactive',
}

export const enum Platform {
  manual = 'manual',
  stripe = 'stripe',
}

export interface Subscription {
  next_payment_at: string
  ends_at: string
  status: Status
  interval: RateType
  amount: number
  plan: Plan
  platform: Platform
  trial_end: string
  is_trial: boolean
  plan_features: Record<Feature, boolean>
}

export interface UnsubscribeQuestion {
  id: number
  title: string
  type: string
}

export interface UnsubscribeQuestionAnswer {
  question: number
  answer: {
    unsubscribe: boolean
    reason: string
    message: string
  }
}
