import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'clsx'
import { AxiosError } from 'axios'
import { UseMutationResult } from '@tanstack/react-query'
import { twMerge } from 'tailwind-merge'

import AiChatInput from './components/Input'
import Greetings from './components/Greetings'
import Conversation from './components/Conversation'
import NotConnectedBadge from './components/NotConnectedBadge'
import Loading from '@components/Loading'

import { requestErrorsHandler } from '@helpers/utils'

import { RequestData } from '@queries/request'
import useGetIsApproveitAiConnected from '@helpers/hooks/useGetIsApproveitAiConnected'

import { ReportResponse, ReportsPayload } from '@models/DWH'
import { ConversationItem, Sender } from './type'

interface AIChatProps {
  dataHook(): UseMutationResult<ReportResponse, AxiosError<unknown>, RequestData<ReportsPayload>, unknown>

  hideSuggestions?: boolean
  hideLogo?: boolean
  classes?: {
    container?: string
    input?: Record<string, string>
  }
}

const AIChat: FC<AIChatProps> = ({ dataHook, hideSuggestions, hideLogo, classes }) => {
  const { mutateAsync: sendPrompt, isLoading } = dataHook()
  const [messages, setChatHistory] = useState<ConversationItem[]>([])
  const bottomOfMessagesRef = useRef<HTMLDivElement>(null)
  const { isConnected: isAvailable, isLoading: checkingIfAvailable } = useGetIsApproveitAiConnected()

  const handleSendPrompt = useCallback(
    async (prompt: string) => {
      if (isLoading) {
        return
      }
      try {
        setChatHistory((st) => [...st, { sender: Sender.me, message: prompt }])
        const response = await sendPrompt({ prompt })
        setChatHistory((st) => [...st, { sender: Sender.ai, message: response.report }])
      } catch (e) {
        requestErrorsHandler(e)
      }
    },
    [sendPrompt, isLoading, bottomOfMessagesRef],
  )

  useEffect(() => {
    bottomOfMessagesRef.current?.scrollIntoView()
  }, [messages])

  if (checkingIfAvailable) {
    return <Loading loading />
  }

  return (
    <div className={twMerge(cx('w-full h-full max-w-[44rem] relative mx-auto', classes?.container))}>
      {!isAvailable && <NotConnectedBadge />}
      <div
        className={twMerge(
          cx('w-full h-full grid grid-rows-[1fr_auto]', !isAvailable && '!pointer-events-none opacity-70'),
        )}
      >
        {!messages.length ? (
          <Greetings onSuggestionClick={handleSendPrompt} hideSuggestions={hideSuggestions} hideLogo={hideLogo} />
        ) : (
          <Conversation messages={messages} isAwaitingAnswer={isLoading} ref={bottomOfMessagesRef} />
        )}
        <AiChatInput
          sendPrompt={handleSendPrompt}
          isLoading={isLoading}
          classes={classes?.input}
          disabled={!isAvailable}
        />
      </div>
    </div>
  )
}

export default AIChat
