import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { promptSuggestions } from '@components/AIChat/constants'

import styles from '../index.module.css'

interface GreetingsProps {
  onSuggestionClick(suggestion: string): void

  hideSuggestions?: boolean
  hideLogo?: boolean
}

const Greetings: FC<GreetingsProps> = ({ onSuggestionClick, hideSuggestions, hideLogo }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'ai' })
  return (
    <div className="flex flex-col grow">
      <div className="flex grow justify-center items-center flex-col gap-4 relative">
        {!hideLogo && (
          <img
            src="/logo/approveit-ai.svg"
            className="w-[3.75rem] h-[3.75rem] rounded-[10px] p-1.5 border border-gray-200"
            alt="Approveit AI"
          />
        )}
        <p className={styles.welcomeMessage}>{t('greetingsMessage')}</p>
      </div>
      {!hideSuggestions && (
        <div className={styles.suggestionsContainer}>
          {promptSuggestions.map((suggestion, i) => (
            <button key={i} className={styles.suggestionCard} onClick={() => onSuggestionClick(suggestion)}>
              {suggestion}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Greetings
