import React, { FC, useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { CSSObjectWithLabel } from 'react-select'
import cx from 'clsx'
import LinkButton from '@src/components/LinkButton'
import routes from '@src/Routes/routes'
import { IconChevronDown, IconCopy, IconInfoCircle } from '@tabler/icons-react'

import SwitchBox from '@components/SwitchBox'
import UserSelect from '@components/UserSelectV2'
import Divider from '@src/components/Divider'
import Collapse from '@src/components/Collapse'
import TimeOffLimits from './components/TimeOffLimits'

import { useGetMyAccount } from '@queries/Account'
import { useUpdateTeamUserInfo } from '@queries/Teams'
import { copyToClipboard, getInitials } from '@helpers/utils'
import { useIsAdmin } from '@src/helpers'

import styles from './index.module.css'

interface ProfileFields {
  is_vacation?: boolean
  substitute?: number | null
}

interface ProfileProps {
  profileSettingsOpen: boolean
  closeProfileSettings: () => void
}

const Profile: FC<ProfileProps> = ({ profileSettingsOpen, closeProfileSettings }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'profile' })

  const { data: user } = useGetMyAccount()
  const { mutateAsync: updateUserData } = useUpdateTeamUserInfo(user?.requestor_id, true)
  const isAdmin = useIsAdmin()
  const [animation, setAnimation] = useState(false)

  const formik = useFormik<ProfileFields>({
    initialValues: {
      is_vacation: user?.is_vacation,
      substitute: user?.substitute,
    },
    onSubmit: () => undefined,
    enableReinitialize: true,
  })

  const [isCollapseOpen, setIsCollapseOpen] = useState(formik.values.is_vacation)

  const handleOnVacationChange = useCallback(async () => {
    if (!formik.values.is_vacation) {
      setAnimation(true)
      setIsCollapseOpen(true)

      setTimeout(() => setAnimation(false), 1800)
    } else {
      formik.setFieldValue('is_vacation', false)
      formik.setFieldValue('substitute', null)
      if (user) {
        await updateUserData({
          ...user,
          full_name: user.name,
          is_vacation: false,
          substitute: null,
        })
      }
      setIsCollapseOpen(false)
    }
  }, [user, formik.values.is_vacation])

  const handleSubstituteChange = useCallback(
    async (value: number | null | undefined) => {
      formik.setFieldValue('substitute', value)
      if (value) {
        formik.setFieldValue('is_vacation', true)
        if (user) {
          await updateUserData({
            ...user,
            full_name: user?.name,
            is_vacation: true,
            substitute: value,
          })
        }
      }
    },
    [user, formik.values.substitute],
  )

  return (
    <form id="settings-form" onSubmit={formik.handleSubmit}>
      <div className={styles.mainWrap}>
        <div className={cx(styles.profileHeader, profileSettingsOpen && styles.profilePopup)}>
          <div className="flex justify-between">
            <div className={styles.avatarPlaceholder}>{getInitials(user?.name)}</div>
            <div className="flex self-center mt-6">
              {profileSettingsOpen && (
                <LinkButton
                  to={routes.private.settings}
                  className="h-6 px-2 mr-1.5"
                  type="outlined"
                  onClick={closeProfileSettings}
                >
                  {t('settings')}
                </LinkButton>
              )}
              <p className={styles.userRole}>{isAdmin ? t('admin') : t('member')}</p>
            </div>
          </div>
          <div className={styles.userNameWrap}>
            <p className="font-medium text-lg">{user?.name}</p>
          </div>
          <p
            className="group w-full text-neutral-400 cursor-pointer"
            onClick={() => copyToClipboard(user?.email || '')}
          >
            {user?.email} <IconCopy className="inline w-4 h-4 group-hover:text-brand_primary" />
          </p>
        </div>
        {profileSettingsOpen && <Divider />}
        <TimeOffLimits />
        <div onClick={() => setIsCollapseOpen(!isCollapseOpen)} className={cx('group', styles.isVacationBox)}>
          <div className="flex items-center">
            <SwitchBox
              className={cx({ [styles.shakeAnimation]: animation })}
              checked={formik.values.is_vacation}
              onChange={handleOnVacationChange}
              name="is_vacation"
            />
            <p className="ml-4 font-medium">{t('onVacationLabel')}</p>
          </div>
          <IconChevronDown
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            stroke={2.5}
            className={cx('group-hover:opacity-100', styles.chevron, {
              'rotate-180': isCollapseOpen,
            })}
          />
        </div>
        <Collapse className="px-[3px]" expanded={isCollapseOpen}>
          <div className={cx(styles.infoBox, { [styles.blinkAnimation]: animation })}>
            <p>{t('infoBox')}</p>
            <IconInfoCircle className="w-7 h-7" />
          </div>
          <UserSelect
            className="w-full"
            label={t('substituteLabel')}
            placeholder={t('substitutePlaceholder')}
            value={formik.values.substitute}
            onChange={handleSubstituteChange}
            filterOptions={(option) => option.value !== user?.requestor_id}
            styles={{
              menuList: (provided: CSSObjectWithLabel) => ({
                ...provided,
                maxHeight: provided.maxHeight ? +provided.maxHeight - 50 : undefined, // layout shifting fix
              }),
            }}
          />
        </Collapse>
      </div>
    </form>
  )
}

export default React.memo(Profile)
