import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useCheckoutStripe, useGetTeamSubscription, useUpgradeStripe } from '@queries/Billing'
import { requestErrorsHandler } from '@helpers/utils'
import { isPlanLowerThanCurrent } from '../utils'
import { ENTERPRISE_REQUEST_LINK } from '@src/constants'
import { STRIPE_PLANS } from '../constants'

import { Plan } from '@type/common'
import { RateType } from '@type/billing'
import { Platform } from '@models/Billing'
import useGetAccount from '@src/helpers/hooks/useGetAccount'
import useIsMSTeams from '@helpers/hooks/useIsMSTeams'

export const useStartNewPlan = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'plans.planConfirmPopup' })

  const isMS = useIsMSTeams()

  const { data: subscription } = useGetTeamSubscription()
  const account = useGetAccount()
  const isOnboardingAttended = account?.team?.is_onboarding_attended

  const { mutateAsync: checkout } = useCheckoutStripe()
  const { mutateAsync: upgrade } = useUpgradeStripe()

  const [productPriceKey, setProductPriceKey] = useState<string | null>(null)

  const isEnterprise = subscription?.plan === Plan.enterprise
  const isManual = subscription?.platform === Platform.manual
  const isTrial = subscription?.is_trial

  const upgradeSubscription = async () => {
    if (productPriceKey) {
      await upgrade({
        price: productPriceKey,
      })
      setProductPriceKey(null)
    }
  }

  const handleSubscribe = async (name: Plan, rate: RateType) => {
    try {
      const productPriceKey = STRIPE_PLANS?.[name]?.[rate]
      if (productPriceKey) {
        if (isManual) {
          const res = await checkout({
            product_price: productPriceKey,
          })
          if (res && res.checkout_url) {
            if (isMS) {
              window.open(res.checkout_url, '_blank')
            } else {
              window.location.replace(res.checkout_url)
            }
          }
        } else {
          setProductPriceKey(productPriceKey || '')
        }
      } else {
        toast.error(t('generalError'))
      }
    } catch (err) {
      requestErrorsHandler(err)
    }
  }

  const handleStartNewPlanClick: React.MouseEventHandler<HTMLButtonElement> = async ({
    currentTarget: {
      name,
      dataset: { frequency },
    },
  }) => {
    if (
      isEnterprise ||
      (!isTrial && isPlanLowerThanCurrent(subscription?.plan, name as Plan) && !isOnboardingAttended)
    ) {
      window.open(ENTERPRISE_REQUEST_LINK, '_blank')
    } else {
      await handleSubscribe(name as Plan, frequency as RateType)
    }
  }

  return useMemo(
    () => ({ handleStartNewPlanClick, upgradeSubscription, productPriceKey, setProductPriceKey }),
    [subscription, productPriceKey],
  )
}
