import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface SoftledgerProps {}

const HERO_IMAGE = '/paywalls/softledger/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:softledger.articles.mapping.title'),
    text: i18n.t('paywalls:softledger.articles.mapping.text'),
    image: '/paywalls/softledger/mapping.png',
  },
  {
    title: i18n.t('paywalls:softledger.articles.billPo.title'),
    text: i18n.t('paywalls:softledger.articles.billPo.text'),
    image: '/paywalls/softledger/bill-po.png',
  },
  {
    title: i18n.t('paywalls:softledger.articles.crypto.title'),
    text: i18n.t('paywalls:softledger.articles.crypto.text'),
    image: '/paywalls/softledger/crypto.png',
  },
]

export const Softledger: React.FC<SoftledgerProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'softledger' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section title={t('section.title')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article, index) => (
          <Article
            key={article.title}
            title={article.title}
            image={article.image}
            text={article.text}
            hasActionButtons={index === ARTICLES.length - 1}
          />
        ))}
      </div>
    </>
  )
}

export default Softledger
