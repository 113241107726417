import React from 'react'
import { useTranslation } from 'react-i18next'

import Hero from '../../components/Hero'
import Article from '../../components/Article'
import Section from '../../components/Section'

export interface CostCentersProps {}

const HERO_IMAGE = '/paywalls/cost-center/hero.png'
const ARTICLE_EXPENSE_IMAGE = '/paywalls/cost-center/expense-tracking.png'
const ARTICLE_BUDGETS_IMAGE = '/paywalls/cost-center/budgets.png'
const ARTICLE_PERIOD_IMAGE = '/paywalls/cost-center/period.png'
const ARTICLE_PO_IMAGE = '/paywalls/cost-center/po.png'

export const CostCenters: React.FC<CostCentersProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'costCenters' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section tip={t('section.tip')} title={t('section.title')} text={t('section.text')} />
      <div className="flex flex-col gap-6">
        <Article
          title={t('articles.expenseTracking.title')}
          image={ARTICLE_EXPENSE_IMAGE}
          text={t('articles.expenseTracking.text')}
        />
        <Article title={t('articles.budgets.title')} image={ARTICLE_BUDGETS_IMAGE} text={t('articles.budgets.text')} />
        <Article title={t('articles.period.title')} image={ARTICLE_PERIOD_IMAGE} text={t('articles.period.text')} />
        <Article
          title={t('articles.po.title')}
          image={ARTICLE_PO_IMAGE}
          text={t('articles.po.text')}
          hasActionButtons
        />
      </div>
    </>
  )
}

export default CostCenters
