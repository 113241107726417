import React from 'react'
import { Outlet } from 'react-router-dom'

export interface ContactLayoutProps {}

export const ContactLayout: React.FC<ContactLayoutProps> = () => {
  return (
    <div className="flex flex-col h-full">
      <Outlet />
    </div>
  )
}

export default ContactLayout
