import React, { useEffect, useRef, useState } from 'react'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { IconPlus } from '@tabler/icons-react'
import { ControlledMenu, MenuItem, useClick, useHover, useMenuState } from '@szhsin/react-menu'
import Paywall from '@pages/Pipelines/components/Paywall'
import PaywallRequests from '@pages/ApprovalRequests/Paywall'
import Tooltip from '@src/components/Tooltip'

import { useGetMyAccount } from '@queries/Account'
import { useGetStats } from '@queries/Dashboard'
import useAddWorkflowClick from '@pages/Pipelines/hooks/useAddWorkflowClick'

import { QUICK_ACTIONS_ITEMS } from '../menuItems'
import { filterNavItems, useWindowControlsOverlay } from '../utils'
import routes from '@src/Routes/routes'

import styles from '../index.module.css'
import useAddRequestClick from '@src/pages/ApprovalRequests/RequestsList/hooks/useAddRequestClick'

const QuickMenu = () => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })

  const { data: user } = useGetMyAccount()
  const { data: stats } = useGetStats()
  const isWindowControlsOverlay = useWindowControlsOverlay()
  const onboarded = user?.team?.onboarded

  const { handleAddWorkflowClick, handleTogglePopup, isPopupOpen } = useAddWorkflowClick(stats?.workflows)
  const {
    handleAddRequestClick,
    handleTogglePopup: handleToggleRequestPopup,
    isPopupOpen: isRequestPopupOpen,
  } = useAddRequestClick(stats?.current_month_requests)

  const ref = useRef(null)

  const [showTooltip, setShowTooltip] = useState(true)
  const [menuState, toggle] = useMenuState({ transition: true })
  const clickProps = useClick(menuState.state === 'open', toggle)
  const { anchorProps: hoverAnchorProps, hoverProps } = useHover(menuState.state, toggle)

  const actualAnchorProps = isWindowControlsOverlay ? hoverAnchorProps : clickProps
  const menuProps = isWindowControlsOverlay ? hoverProps : {}

  useEffect(() => {
    if (menuState.state === 'opening' || menuState.state === 'open') {
      window.Intercom('hide')
      setShowTooltip(false)
    } else {
      setShowTooltip(true)
    }
  }, [menuState.state])

  return (
    <>
      {isPopupOpen && <Paywall onClose={handleTogglePopup} workflowCount={stats?.workflows} />}
      {isRequestPopupOpen && (
        <PaywallRequests
          requestsCount={user?.team?.subscription.subscription_requests}
          onClose={handleToggleRequestPopup}
        />
      )}
      <Tooltip offset={10} id="tooltip_quick" place="bottom" />
      <div
        className={cx(styles.navLinkButtonWrap, 'active:scale-95')}
        {...(showTooltip ? { 'data-tooltip-content': t('quickAccess') } : {})}
        data-tooltip-id="tooltip_quick"
        ref={ref}
        {...actualAnchorProps}
      >
        <div className={cx(styles.quickMenuWrap)}>
          <IconPlus className="text-white h-4 w-4" stroke={2} />
        </div>
      </div>

      <ControlledMenu
        {...menuProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
        arrow
        arrowProps={{ className: 'border-l-gray-200 border-t-gray-200 -ml-4' }}
        align="end"
        menuClassName="w-42 !ml-[15px] bg-white rounded-base py-1.5 shadow-lg border-solid border border-gray-200"
      >
        <p className="leading-6 px-4 text-sm font-bold text-grey_1">{t('quickAccess')}</p>

        {user &&
          filterNavItems(QUICK_ACTIONS_ITEMS, user).map((item, index) => {
            const IconComponent = item.icon
            const isWorkflowCreate = item.to === generatePath(routes.private.pipelineById, { id: 'new' })
            const isRequestCreate = item.to === routes.private.approvalRequest

            return (
              <MenuItem
                className={cx(styles.navLinkButton, 'group !pr-3', !onboarded && styles.disabled)}
                key={index}
                data-amplitude={item.amplitude}
              >
                {item.to && !isWorkflowCreate && !isRequestCreate && (
                  <Link
                    className="flex items-center gap-3 w-full"
                    to={item.to}
                    state={{ backRoute: location.pathname }}
                    onContextMenu={(e) => e.stopPropagation()}
                  >
                    <IconComponent className="w-4 h-4 group-hover:scale-105" />
                    {item.label}
                  </Link>
                )}
                {isWorkflowCreate && (
                  <button className="flex items-center gap-3 w-full" onClick={handleAddWorkflowClick}>
                    <IconComponent className="w-4 h-4 group-hover:scale-105" />
                    {item.label}
                  </button>
                )}
                {isRequestCreate && (
                  <button className="flex items-center gap-3 w-full" onClick={handleAddRequestClick}>
                    <IconComponent className="w-4 h-4 group-hover:scale-105" />
                    {item.label}
                  </button>
                )}
              </MenuItem>
            )
          })}
      </ControlledMenu>
    </>
  )
}

export default QuickMenu
