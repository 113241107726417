import { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { usePopupHandlers } from '@components/Popup/usePopupHandler'
import useGetAccount from '@helpers/hooks/useGetAccount'

import routes from '@src/Routes/routes'
import { Plan } from '@src/types/common'

export const useAddWorkflowClick = (workflowsCount?: number) => {
  const navigate = useNavigate()
  const user = useGetAccount()
  const { handleTogglePopup, isPopupOpen } = usePopupHandlers()

  const hasUnlimitedWorkflows =
    user?.team?.subscription.plan === Plan.enterprise || user?.team?.subscription?.subscription_workflows === -1

  const handleAddWorkflowClick = useCallback(() => {
    if (hasUnlimitedWorkflows || (user?.team?.subscription?.subscription_workflows || 0) > (workflowsCount || 0)) {
      navigate(generatePath(routes.private.pipelineById, { id: 'new' }), {
        state: {
          backRoute: location.pathname,
        },
      })
    } else {
      handleTogglePopup()
    }
  }, [workflowsCount, user?.team])

  return { handleAddWorkflowClick, handleTogglePopup, isPopupOpen }
}

export default useAddWorkflowClick
