import React from 'react'
import { useTranslation } from 'react-i18next'

import Hero from '../../components/Hero'
import Section from '../../components/Section'

export interface IntegrationsProps {}

const HERO_IMAGE = '/paywalls/integrations/hero.svg'
const SECTION_IMAGE = '/paywalls/integrations/section.png'

export const Integrations: React.FC<IntegrationsProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'integrations' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <Section
        tip={t('section.tip')}
        title={t('section.title')}
        text={t('section.text')}
        image={SECTION_IMAGE}
        hasActionButtons
      />
    </>
  )
}

export default Integrations
