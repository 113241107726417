import useLocalStorage from '../useLocalStorage'

import { SHOW_MAINTENANCE_BANNER } from '@src/constants'

type MaintenaceStoreItem = { show: boolean; expiration: number | null }
type MaintenaceStore = [MaintenaceStoreItem, (storeItem: MaintenaceStoreItem) => void]

export const useGetMaintenanceBanner = (): MaintenaceStore => {
  const maintenanceStore = useLocalStorage('maintenanceBanner', {
    show: SHOW_MAINTENANCE_BANNER,
    expiration: null,
  }) as MaintenaceStore

  return maintenanceStore
}
