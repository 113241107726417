import React from 'react'
import cx from 'clsx'
import { NavLink, generatePath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from '@src/Routes/routes'
import { useGetContactParty } from '../../utils'
import { useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'

import { Feature } from '@type/features'

import styles from './index.module.css'
import ContentLock from '@src/components/ContentLock'

export interface HeaderProps {
  id?: string
}

export const Header: React.FC<HeaderProps> = ({ id }) => {
  const { t } = useTranslation('accounting', { keyPrefix: 'contact' })
  const { label, party: contactParty } = useGetContactParty()
  const location = useLocation()
  const { paywallPath } = useGetIsFeatureAvailable(Feature.contractManagement)
  return (
    <div className="flex items-center justify-between border-b flex-wrap-reverse gap-2 mb-4">
      <nav className="flex">
        <NavLink
          to={generatePath(routes.private.contact, { id, contactParty })}
          state={{ backRoute: location.pathname }}
          className={({ isActive }) => {
            return cx(styles.tab, { [styles.active]: isActive && !location.pathname.includes('contract') })
          }}
        >
          {t('detailsLabel', { party: label })}
        </NavLink>
        {id !== 'new' && (
          <NavLink
            to={paywallPath || generatePath(routes.private.contactContracts, { id, contactParty })}
            className={({ isActive }) => cx('group', styles.tab, { [styles.active]: isActive })}
          >
            {t('contact.contractsLabel')}
            {paywallPath && <ContentLock />}
          </NavLink>
        )}
      </nav>
    </div>
  )
}

export default Header
