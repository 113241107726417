import React, { FC, useRef } from 'react'
import cx from 'clsx'

import styles from './index.module.css'

const TEXT_BLOCK_WITH = 66

export interface ProgressBarProps {
  completedValue: number
  hideNumbers?: boolean
  containerStyles?: string
  barStyles?: string
}

export const ProgressBar: FC<ProgressBarProps> = ({
  completedValue,
  hideNumbers = false,
  containerStyles,
  barStyles,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const isTextInside = ref && ref.current ? ref.current.offsetWidth > TEXT_BLOCK_WITH : false

  const fillerStyle = {
    width: `${completedValue}%`,
    borderRadius: 'inherit',
  }

  return (
    <div className={cx(styles.container, containerStyles)}>
      <div className={cx(styles.filter, barStyles)} style={fillerStyle} ref={ref}>
        {!hideNumbers && isTextInside && <p className={styles.insideLabel}>{`${completedValue}/100`}</p>}
      </div>
      {!hideNumbers && !isTextInside && <span className={styles.outsideLabel}>{`${completedValue}/100`}</span>}
    </div>
  )
}

export default ProgressBar
