import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'

import { useGetTeamSubscription } from '@queries/Billing'
import useGetAccount from '@helpers/hooks/useGetAccount'

import styles from '../../index.module.css'
import LinkButton from '@src/components/LinkButton'
import { useGetQuestionnaireAnswers } from '@src/api/queries/Teams'

export interface TrialCounterProps {}

export const TrialCounter: React.FC<TrialCounterProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'trialCounter' })
  const user = useGetAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })
  const { data: answers } = useGetQuestionnaireAnswers()

  const diffDays = subscription?.trial_end ? differenceInDays(new Date(subscription?.trial_end), new Date()) : 0
  const demoPath = useMemo(() => {
    const demoPath = new URL(import.meta.env.VITE_INAPP_DEMO_LINK || '')
    demoPath.searchParams.set('firstname', user?.name?.split(' ')?.[0] || '')
    demoPath.searchParams.set('lastname', user?.name?.split(' ')?.[1] || '')
    demoPath.searchParams.set('email', user?.email || '')
    demoPath.searchParams.set('company', user?.team?.name || '')
    const phone = answers?.find((answer) => answer[1].startsWith('+'))?.[1]
    demoPath.searchParams.set('phone', phone || '')

    return demoPath.toString()
  }, [user, answers])
  return (
    <div className="h-12 text-sm sm:text-lg bg-violet-700 text-white flex items-center justify-center gap-2">
      <p>
        <Trans
          i18nKey="trialLeftMessage"
          count={diffDays < 0 ? 0 : diffDays + 1}
          t={t}
          components={[<span className={styles.trialCounter} key={0} />]}
        />
      </p>
      <LinkButton
        to={demoPath}
        reloadDocument
        target="_blank"
        color="primary"
        type="outlined"
        className="text-sm sm:text-base px-1 sm:px-4"
      >
        {t('bookDemoLabel')}
      </LinkButton>
    </div>
  )
}

export default TrialCounter
