import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { useReactTable, getCoreRowModel, flexRender, CellContext } from '@tanstack/react-table'

import Collapsible from '@src/components/Collapsible'
import { featuresTable, PlanFeatures } from '../constants'

import styles from '../index.module.css'

type PlanValue = string | boolean | undefined

type FeatureRow = {
  [key: string]: PlanValue
}

export const PlansComparisonTable: React.FC = () => {
  const { t } = useTranslation('billing')
  const collapsibleRef = useRef<HTMLDivElement>(null)

  const [isExpanded, setIsExpanded] = useState(false)
  const features = useMemo(
    () => Array.from(new Set(Object.values(featuresTable).flatMap((plan) => Object.keys(plan || {})))),
    [],
  )
  const prepareTableData = (features: string[], planFeatures: PlanFeatures): FeatureRow[] => {
    return features.map((feature) => {
      const row: FeatureRow = { feature }
      Object.entries(planFeatures).forEach(([plan, planData]) => {
        row[plan] = (planData as Record<string, PlanValue>)[feature] ?? false
      })
      return row
    })
  }

  const columns = useMemo(() => {
    return [
      {
        header: () => <span className="font-medium">{t('plans.featuresTitle')}</span>,
        accessorKey: 'feature',
      },
      ...Object.keys(featuresTable).map((plan) => ({
        header: <span className="capitalize">{plan}</span>,
        accessorKey: plan,
        cell: (context: CellContext<FeatureRow, PlanValue>) => {
          const value = context.getValue()

          if (typeof value === 'boolean') {
            return value ? (
              <div className="flex justify-center">
                <div className={styles.bullet} />
              </div>
            ) : null
          } else {
            return <div className="text-center whitespace-nowrap text-gray-500">{value}</div>
          }
        },
      })),
    ]
  }, [])

  const data = useMemo(() => prepareTableData(features, featuresTable), [])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        collapsibleRef.current && collapsibleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 333)
    }
  }, [isExpanded])

  return (
    <div className={cx('hidden sm:block px-1 xs:px-2 sm:px-3 md:px-5 lg:px-7 xl:px-8 2xl:px-16')} ref={collapsibleRef}>
      <Collapsible
        expanded={isExpanded}
        onToggle={() => setIsExpanded((prev) => !prev)}
        controlOutside
        collapsedComponent={
          <p className={cx('text-[20px] font-semibold  pl-8', isExpanded ? 'text-slate-950' : 'text-white')}>
            {t('comparePlans')}
          </p>
        }
        collapsedClassName={cx(
          'h-20 rounded-xl',
          styles.collapsed,
          isExpanded ? 'bg-white' : 'bg-brand_primary bg-no-repeat bg-center',
        )}
        iconClassName={cx('text-white transform -rotate-90 mr-8', isExpanded && 'rotate-[-270deg] !text-slate-950')}
        className={twMerge(
          styles.tableContainer,
          'mx-4 px-0 mb-12 overflow-hidden shadow-md rounded-xl',
          isExpanded && 'border border-gray-200',
        )}
      >
        <div className="flex flex-col gap-2 border-t border-gray-200 overflow-x-auto">
          <table className="w-full border-collapse rounded-b-xl">
            <thead className="sticky !-top-10 bg-white text-[20px]">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="h-11" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={cx(
                        header.id === 'feature' ? 'text-left' : '',
                        'border-b px-8 border-gray-200 p-2 whitespace-nowrap',
                      )}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="text-[16px]">
              {table.getRowModel().rows.map((row, index) => {
                const isLastRow = index === table.getRowModel().rows.length - 1
                return (
                  <tr key={row.id} className={cx('h-11', index % 2 !== 0 ? '' : 'bg-neutral-50')}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={cx('p-2 px-8 whitespace-nowrap', {
                          'border-b border-gray-200': !isLastRow,
                          'w-96 text-left font-medium text-slate-950': cell.column.id === 'feature',
                          'w-32': cell.column.id !== 'feature',
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Collapsible>
    </div>
  )
}

export default PlansComparisonTable
