import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'

export interface GoogleCalendarProps {}

const HERO_IMAGE = '/paywalls/google-calendar/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:googleCalendar.articles.sync.title'),
    text: i18n.t('paywalls:googleCalendar.articles.sync.text'),
    image: '/paywalls/google-calendar/sync.png',
  },
]

export const GoogleCalendar: React.FC<GoogleCalendarProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'googleCalendar' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article) => (
          <Article key={article.title} title={article.title} image={article.image} text={article.text} />
        ))}
      </div>
    </>
  )
}

export default GoogleCalendar
