import React from 'react'
import cx from 'clsx'

import ActionButtons from '../ActionButtons'
import SuspenseImage from '@components/SuspenseImage'

import styles from './index.module.css'

export interface HeroProps {
  title: string
  subtitle: string
  image: string
}

export const Hero: React.FC<HeroProps> = ({ title, subtitle, image }) => {
  return (
    <>
      <div className="flex gap-9 justify-between mb-16">
        <div className="md:w-1/2 flex flex-col gap-4">
          <h1 className={cx('text-5xl lg:text-[54px] font-bold md:leading-10 lg:leading-[64px]', styles.titleGradient)}>
            {title}
          </h1>
          <p className="text-lg">{subtitle}</p>
          <ActionButtons buttonClassName="sm:h-10 lg:text-lg" />
        </div>
        <div className="w-1/2 hidden md:block">
          <SuspenseImage src={image} className="resize overflow-auto" alt={title} />
        </div>
      </div>
    </>
  )
}

export default Hero
