import React, { useEffect, useState } from 'react'

import List from './components/List'
import { useGetReports } from '@queries/Reports'

const ManualReports = () => {
  const [search, setSearch] = useState('')
  const { data: reports, refetch } = useGetReports({
    search,
  })

  useEffect(() => {
    refetch()
  }, [search])

  return <List reports={reports} updateSearchString={setSearch} />
}

export default ManualReports
