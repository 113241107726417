import i18n from '@src/i18n'
import {
  STRIPE_BASIC_ANNUAL_PAYMENT_KEY,
  STRIPE_EASYPEASY_ANNUAL_PAYMENT_KEY,
  STRIPE_EASYPEASY_MONTHLY_PAYMENT_KEY,
  STRIPE_PRO_ANNUAL_PAYMENT_KEY,
  STRIPE_STARTER_ANNUAL_PAYMENT_KEY,
  STRIPE_STARTER_MONTHLY_PAYMENT_KEY,
} from '@src/constants'
import { RateType } from '@src/types/billing'
import { Plan } from '@src/types/common'

export const STRIPE_PLANS: { [key: string]: { [key: string]: string | undefined } } = {
  [Plan.easyPeasy as string]: {
    [RateType.annually as string]: STRIPE_EASYPEASY_ANNUAL_PAYMENT_KEY,
    [RateType.monthly as string]: STRIPE_EASYPEASY_MONTHLY_PAYMENT_KEY,
  },
  [Plan.starter as string]: {
    [RateType.annually as string]: STRIPE_STARTER_ANNUAL_PAYMENT_KEY,
    [RateType.monthly as string]: STRIPE_STARTER_MONTHLY_PAYMENT_KEY,
  },
  [Plan.basic as string]: {
    [RateType.annually as string]: STRIPE_BASIC_ANNUAL_PAYMENT_KEY,
  },
  [Plan.pro as string]: {
    [RateType.annually as string]: STRIPE_PRO_ANNUAL_PAYMENT_KEY,
  },
}

export interface PlanData {
  name: string
  priority: number
  priceAnnualy?: number
  priceAnnualyPerMonth?: number
  priceMonthly?: number
  shortDescription?: string
  show?: boolean
  label?: string
  browColor?: string
  features?: string[]
  featuresForTable?: string[]
  mainFeatures?: string[]
  customPriceAnnual?: string
  customPriceMonthly?: string
  teamSize: TeamSize
}

export type PlanFeatures = Record<string, string | boolean | object | null | undefined>

export enum PlansPrice {
  easyPeasyAnnualy = 900,
  easyPeasyAnnualyPerMonth = 75,
  easyPeasyMonthly = 89,

  starterAnnualy = 1800,
  starterAnnualyPerMonth = 150,
  starterMonthly = 189,

  basicAnnualy = 5400,
  basicAnnualyPerMonth = 450,
  proAnnualy = 7200,
  proAnnualyPerMonth = 600,
}

export enum TeamSize {
  small = 'small',
  large = 'large',
}

export const plansData: Record<Plan, PlanData> = {
  [Plan.free]: {
    name: i18n.t('billing:plans.free.name'),
    priority: 0,
    browColor: 'bg-violet-100',
    teamSize: TeamSize.small,
    show: true,
    shortDescription: i18n.t('billing:plans.free.shortDescription'),
    customPriceAnnual: i18n.t('billing:plans.free.customPriceAnnual'),
    customPriceMonthly: i18n.t('billing:plans.free.customPriceMonthly'),
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasksWithLimit', { count: 3 }),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
    ],
    mainFeatures: [
      i18n.t('billing:plans.features.wf', { count: 1 }),
      i18n.t('billing:plans.features.requests', { count: 20 }),
    ],
  },
  [Plan.easyPeasy]: {
    name: i18n.t('billing:plans.easyPeasy.name'),
    teamSize: TeamSize.small,
    priority: 1,
    browColor: 'bg-violet-300',
    priceAnnualy: PlansPrice.easyPeasyAnnualy,
    priceAnnualyPerMonth: PlansPrice.easyPeasyAnnualyPerMonth,
    priceMonthly: PlansPrice.easyPeasyMonthly,
    shortDescription: i18n.t('billing:plans.easyPeasy.shortDescription'),
    show: true,
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasksWithLimit', { count: 3 }),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
      i18n.t('billing:plans.features.accountsPayable'),
      i18n.t('billing:plans.features.accountsReceivable'),
      i18n.t('billing:plans.features.integrationsXeroQboSi'),
      i18n.t('billing:plans.features.mailbox'),
      i18n.t('billing:plans.features.zapier'),
    ],
    mainFeatures: [
      i18n.t('billing:plans.features.wf', { count: 2 }),
      i18n.t('billing:plans.features.requests', { count: 50 }),
    ],
  },
  [Plan.starter]: {
    name: i18n.t('billing:plans.starter.name'),
    teamSize: TeamSize.small,
    priority: 2,
    browColor: 'bg-brand_primary',
    priceAnnualy: PlansPrice.starterAnnualy,
    priceAnnualyPerMonth: PlansPrice.starterAnnualyPerMonth,
    priceMonthly: PlansPrice.starterMonthly,
    shortDescription: i18n.t('billing:plans.starter.shortDescription'),
    show: true,
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasksWithLimit', { count: 3 }),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
      i18n.t('billing:plans.features.accountsPayable'),
      i18n.t('billing:plans.features.accountsReceivable'),
      i18n.t('billing:plans.features.integrationsXeroQboSi'),
      i18n.t('billing:plans.features.mailbox'),
      i18n.t('billing:plans.features.zapier'),
      i18n.t('billing:plans.features.contractManagement'),
    ],
    mainFeatures: [
      i18n.t('billing:plans.features.wf', { count: 3 }),
      i18n.t('billing:plans.features.requests', { count: 100 }),
    ],
  },
  [Plan.basic]: {
    name: i18n.t('billing:plans.basic.name'),
    teamSize: TeamSize.large,
    priority: 3,
    priceAnnualy: PlansPrice.basicAnnualy,
    priceAnnualyPerMonth: PlansPrice.basicAnnualyPerMonth,
    shortDescription: i18n.t('billing:plans.basic.shortDescription'),
    show: true,
    browColor: 'bg-indigo-100',
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasks', { count: 3 }),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
      i18n.t('billing:plans.features.accountsPayable'),
      i18n.t('billing:plans.features.accountsReceivable'),
      i18n.t('billing:plans.features.integrationsXeroQboSiUnlim'),
      i18n.t('billing:plans.features.mailbox'),
      i18n.t('billing:plans.features.zapier'),
      i18n.t('billing:plans.features.contractManagement'),
      i18n.t('billing:plans.features.costCenters'),
      i18n.t('billing:plans.features.aiReports'),
      i18n.t('billing:plans.features.googleCalendarIntegration'),
      i18n.t('billing:plans.features.api'),
      i18n.t('billing:plans.features.bambooHrIntegration'),
      i18n.t('billing:plans.features.personalManager'),
      i18n.t('billing:plans.features.approveitESignature'),
    ],
    mainFeatures: [
      i18n.t('billing:plans.features.wf', { count: 5 }),
      i18n.t('billing:plans.features.requests', { count: 300 }),
    ],
  },
  [Plan.pro]: {
    name: i18n.t('billing:plans.pro.name'),
    priority: 4,
    teamSize: TeamSize.large,
    priceAnnualyPerMonth: PlansPrice.proAnnualyPerMonth,
    priceAnnualy: PlansPrice.proAnnualy,
    shortDescription: i18n.t('billing:plans.pro.shortDescription'),
    show: true,
    browColor: 'bg-indigo-400',
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasks', { count: 3 }),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
      i18n.t('billing:plans.features.accountsPayable'),
      i18n.t('billing:plans.features.accountsReceivable'),
      i18n.t('billing:plans.features.integrationsXeroQboSiUnlim'),
      i18n.t('billing:plans.features.mailbox'),
      i18n.t('billing:plans.features.zapier'),
      i18n.t('billing:plans.features.contractManagement'),
      i18n.t('billing:plans.features.costCenters'),
      i18n.t('billing:plans.features.aiReports'),
      i18n.t('billing:plans.features.googleCalendarIntegration'),
      i18n.t('billing:plans.features.api'),
      i18n.t('billing:plans.features.bambooHrIntegration'),
      i18n.t('billing:plans.features.personalManager'),
      i18n.t('billing:plans.features.approveitESignature'),
      i18n.t('billing:plans.features.customReports'),
    ],
    mainFeatures: [
      i18n.t('billing:plans.features.wf', { count: 15 }),
      i18n.t('billing:plans.features.requests', { count: 1000 }),
    ],
  },
  [Plan.enterprise]: {
    name: i18n.t('billing:plans.enterprise.name'),
    priority: 5,
    customPriceAnnual: i18n.t('billing:plans.enterprise.customPriceAnnual'),
    customPriceMonthly: i18n.t('billing:plans.enterprise.customPriceMonthly'),
    teamSize: TeamSize.large,
    shortDescription: i18n.t('billing:plans.enterprise.shortDescription'),
    show: true,
    browColor: 'bg-indigo-700',
    features: [
      i18n.t('billing:plans.features.builder'),
      i18n.t('billing:plans.features.customWf'),
      i18n.t('billing:plans.features.multiStepConditionalConfirmation'),
      i18n.t('billing:plans.features.stampedApprovalConfirmation'),
      i18n.t('billing:plans.features.tasks'),
      i18n.t('billing:plans.features.customNotifications'),
      i18n.t('billing:plans.features.automaticReminders'),
      i18n.t('billing:plans.features.integrationsSlackTeams'),
      i18n.t('billing:plans.features.teamworkOrgStructure'),
      i18n.t('billing:plans.features.collaboration'),
      i18n.t('billing:plans.features.basicAccessManagement'),
      i18n.t('billing:plans.features.basicReports'),
      i18n.t('billing:plans.features.auditLog'),
      i18n.t('billing:plans.features.soc2Type1'),
      i18n.t('billing:plans.features.accountsPayable'),
      i18n.t('billing:plans.features.accountsReceivable'),
      i18n.t('billing:plans.features.integrationsXeroQboSiUnlim'),
      i18n.t('billing:plans.features.mailbox'),
      i18n.t('billing:plans.features.zapier'),
      i18n.t('billing:plans.features.contractManagement'),
      i18n.t('billing:plans.features.costCenters'),
      i18n.t('billing:plans.features.aiReports'),
      i18n.t('billing:plans.features.googleCalendarIntegration'),
      i18n.t('billing:plans.features.api'),
      i18n.t('billing:plans.features.bambooHrIntegration'),
      i18n.t('billing:plans.features.personalManager'),
      i18n.t('billing:plans.features.approveitESignature'),
      i18n.t('billing:plans.features.customReports'),
      i18n.t('billing:plans.features.featurePriority'),
      i18n.t('billing:plans.features.customIntegrations'),
      i18n.t('billing:plans.features.netSuite'),
      i18n.t('billing:plans.features.premiumSupport'),
    ],
    mainFeatures: [i18n.t('billing:plans.features.wfInfinity'), i18n.t('billing:plans.features.infinityRequest')],
  },
  // not listed plans
  [Plan.custom]: {
    priority: 6,
    name: i18n.t('billing:plans.custom.name'),
    show: false,
    features: [i18n.t('billing:plans.features.customFeatures')],
    mainFeatures: [i18n.t('billing:plans.features.customFeatures')],
    teamSize: TeamSize.large,
    browColor: 'bg-gradient-to-r from-violet-700 to-teal-400',
  },
  [Plan.pilot]: {
    priority: 7,
    name: i18n.t('billing:plans.pilot.name'),
    browColor: 'bg-gradient-to-r from-violet-700 to-teal-400',
    show: false,
    teamSize: TeamSize.large,
    mainFeatures: [i18n.t('billing:plans.features.allFeatures')],
  },
  [Plan.proPlus]: {
    priority: 3,
    name: i18n.t('billing:plans.proPlus.name'),
    show: false,
    teamSize: TeamSize.large,
  },
}

export const featuresTable: PlanFeatures = {
  [Plan.free]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:upTo', { count: 1 }),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:upTo', { count: 20 }),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: i18n.t('billing:plans.table.boards', { count: 3 }),
  },
  [Plan.easyPeasy]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:upTo', { count: 2 }),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:upTo', { count: 50 }),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: i18n.t('billing:plans.table.boards', { count: 3 }),
    [i18n.t('billing:plans.features.accountsPayable')]: true,
    [i18n.t('billing:plans.features.accountsReceivable')]: true,
    [i18n.t('billing:plans.table.integrationsXeroQboSi')]: i18n.t('billing:plans.table.integrations', { count: 1 }),
    [i18n.t('billing:plans.features.mailbox')]: true,
    [i18n.t('billing:plans.features.zapier')]: true,
  },
  [Plan.starter]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:upTo', { count: 3 }),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:upTo', { count: 100 }),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: i18n.t('billing:plans.table.boards', { count: 3 }),
    [i18n.t('billing:plans.features.accountsPayable')]: true,
    [i18n.t('billing:plans.features.accountsReceivable')]: true,
    [i18n.t('billing:plans.table.integrationsXeroQboSi')]: i18n.t('billing:plans.table.integrations', { count: 1 }),
    [i18n.t('billing:plans.features.mailbox')]: true,
    [i18n.t('billing:plans.features.zapier')]: true,
    [i18n.t('billing:plans.features.contractManagement')]: true,
  },
  [Plan.basic]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:upTo', { count: 5 }),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:upTo', { count: 300 }),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: true,
    [i18n.t('billing:plans.features.accountsPayable')]: true,
    [i18n.t('billing:plans.features.accountsReceivable')]: true,
    [i18n.t('billing:plans.table.integrationsXeroQboSi')]: true,
    [i18n.t('billing:plans.features.mailbox')]: true,
    [i18n.t('billing:plans.features.zapier')]: true,
    [i18n.t('billing:plans.features.contractManagement')]: true,
    [i18n.t('billing:plans.features.costCenters')]: true,
    [i18n.t('billing:plans.features.aiReports')]: true,
    [i18n.t('billing:plans.features.googleCalendarIntegration')]: true,
    [i18n.t('billing:plans.features.api')]: true,
    [i18n.t('billing:plans.features.bambooHrIntegration')]: true,
    [i18n.t('billing:plans.features.personalManager')]: true,
    [i18n.t('billing:plans.features.approveitESignature')]: true,
  },
  [Plan.pro]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:upTo', { count: 15 }),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:upTo', { count: 1000 }),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: true,
    [i18n.t('billing:plans.features.accountsPayable')]: true,
    [i18n.t('billing:plans.features.accountsReceivable')]: true,
    [i18n.t('billing:plans.table.integrationsXeroQboSi')]: true,
    [i18n.t('billing:plans.features.mailbox')]: true,
    [i18n.t('billing:plans.features.zapier')]: true,
    [i18n.t('billing:plans.features.contractManagement')]: true,
    [i18n.t('billing:plans.features.costCenters')]: true,
    [i18n.t('billing:plans.features.aiReports')]: true,
    [i18n.t('billing:plans.features.googleCalendarIntegration')]: true,
    [i18n.t('billing:plans.features.api')]: true,
    [i18n.t('billing:plans.features.bambooHrIntegration')]: true,
    [i18n.t('billing:plans.features.personalManager')]: true,
    [i18n.t('billing:plans.features.approveitESignature')]: true,
    [i18n.t('billing:plans.features.customReports')]: true,
  },
  [Plan.enterprise]: {
    [i18n.t('billing:plans.table.wf')]: i18n.t('billing:plans.table.unlimited'),
    [i18n.t('billing:plans.table.requests')]: i18n.t('billing:plans.table.unlimited'),
    [i18n.t('billing:plans.features.builder')]: true,
    [i18n.t('billing:plans.features.customWf')]: true,
    [i18n.t('billing:plans.features.multiStepConditionalConfirmation')]: true,
    [i18n.t('billing:plans.features.stampedApprovalConfirmation')]: true,
    [i18n.t('billing:plans.features.customNotifications')]: true,
    [i18n.t('billing:plans.features.automaticReminders')]: true,
    [i18n.t('billing:plans.features.integrationsSlackTeams')]: true,
    [i18n.t('billing:plans.features.teamworkOrgStructure')]: true,
    [i18n.t('billing:plans.features.collaboration')]: true,
    [i18n.t('billing:plans.features.basicAccessManagement')]: true,
    [i18n.t('billing:plans.features.basicReports')]: true,
    [i18n.t('billing:plans.features.auditLog')]: true,
    [i18n.t('billing:plans.features.soc2Type1')]: true,
    [i18n.t('billing:plans.table.tasks')]: true,
    [i18n.t('billing:plans.features.accountsPayable')]: true,
    [i18n.t('billing:plans.features.accountsReceivable')]: true,
    [i18n.t('billing:plans.table.integrationsXeroQboSi')]: true,
    [i18n.t('billing:plans.features.mailbox')]: true,
    [i18n.t('billing:plans.features.zapier')]: true,
    [i18n.t('billing:plans.features.contractManagement')]: true,
    [i18n.t('billing:plans.features.costCenters')]: true,
    [i18n.t('billing:plans.features.aiReports')]: true,
    [i18n.t('billing:plans.features.googleCalendarIntegration')]: true,
    [i18n.t('billing:plans.features.api')]: true,
    [i18n.t('billing:plans.features.bambooHrIntegration')]: true,
    [i18n.t('billing:plans.features.personalManager')]: true,
    [i18n.t('billing:plans.features.approveitESignature')]: true,
    [i18n.t('billing:plans.features.customReports')]: true,
    [i18n.t('billing:plans.features.featurePriority')]: true,
    [i18n.t('billing:plans.features.customIntegrations')]: true,
    [i18n.t('billing:plans.features.netSuite')]: true,
    [i18n.t('billing:plans.features.premiumSupport')]: true,
  },
}

export const MS_CONTACT_US_LINK = 'https://meetings.hubspot.com/serge-gusev/demo-30-min'
