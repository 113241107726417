import React from 'react'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'

import Tooltip from '@src/components/Tooltip'
import { IconFileTextAi } from '@tabler/icons-react'
import Popup, { usePopupHandlers } from '@components/Popup'
import Divider from '@components/Divider'
import AIChat from '@components/AIChat'

import { useGetReports } from '@queries/DWH'

import styles from '../index.module.css'

const AIAssistantButton = () => {
  const { t } = useTranslation('common')
  const { isPopupOpen, setIsPopupOpen } = usePopupHandlers()

  return (
    <>
      <Tooltip offset={10} id="tooltip_ai" place="bottom" />
      <button
        className={cx(styles.navLinkButtonWrap, styles.chatButton, 'active:scale-95')}
        data-tooltip-content={t('navigation.aiAssistant')}
        data-tooltip-id="tooltip_ai"
        onClick={() => setIsPopupOpen(true)}
      >
        <IconFileTextAi className={styles.endMenuIcon} stroke={1.5} />
      </button>
      {isPopupOpen && (
        <Popup
          onClose={() => setIsPopupOpen(false)}
          closeButtonStyle="top-3.5 right-4"
          className="p-0 w-[30rem] h-[25.5rem] flex flex-col"
        >
          <div className="flex items-center gap-2 pt-2 px-4 mb-2">
            <img
              src="/logo/approveit-ai.svg"
              className="w-8 h-8 rounded-[10px] p-0.5 border border-gray-200"
              alt="Approveit AI"
            />
            <span className="font-medium text-brand_primary">Approveit AI Assistant</span>
          </div>
          <Divider className="!my-0" />
          <div className="px-5 grow w-full overflow-y-scroll ">
            <AIChat
              dataHook={useGetReports}
              hideSuggestions
              hideLogo
              classes={{ input: { container: '!pb-5 mt-5', control: '!py-1' } }}
            />
          </div>
        </Popup>
      )}
    </>
  )
}

export default AIAssistantButton
