import React from 'react'
import AIChat from '@components/AIChat'

import { useGetReports } from '@queries/DWH'

const AIReports = () => {
  return (
    <div className="flex flex-col flex-grow">
      <div className="flex grow justify-center">
        <AIChat dataHook={useGetReports} />
      </div>
    </div>
  )
}

export default AIReports
