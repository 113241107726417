import React, { useEffect, useRef, useState } from 'react'
import cx from 'clsx'
import { ControlledMenu, useClick, useHover, useMenuState } from '@szhsin/react-menu'

import AccountMenu from '../AccountMenu'
import Tooltip from '@src/components/Tooltip'

import { useGetMyAccount } from '@src/api/queries/Account'
import { useTranslation } from 'react-i18next'

import { useWindowControlsOverlay } from '../utils'
import { getInitials } from '@src/helpers/utils'
import styles from '../index.module.css'

const UserMenu = () => {
  const ref = useRef(null)
  const [menuState, toggle] = useMenuState({ transition: true })
  const [showTooltip, setShowTooltip] = useState(true)
  const clickProps = useClick(menuState.state === 'open', toggle)
  const { anchorProps: hoverAnchorProps, hoverProps } = useHover(menuState.state, toggle)
  const isWindowControlsOverlay = useWindowControlsOverlay()
  const actualAnchorProps = isWindowControlsOverlay ? hoverAnchorProps : clickProps
  const menuProps = isWindowControlsOverlay ? hoverProps : {}
  const { data: user } = useGetMyAccount()
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })

  useEffect(() => {
    if (menuState.state === 'opening' || menuState.state === 'open') {
      window.Intercom('hide')
      setShowTooltip(false)
    } else {
      setShowTooltip(true)
    }
  }, [menuState.state])

  return (
    <>
      <Tooltip offset={10} id="tooltip_account" place="bottom" />

      <div
        className={cx(styles.navLinkButtonWrap, 'active:scale-95')}
        {...(showTooltip ? { 'data-tooltip-content': t('account') } : {})}
        data-tooltip-id="tooltip_account"
        ref={ref}
        {...actualAnchorProps}
      >
        <div className={styles.accountMenuButtonWrap}>
          <span className={styles.accountMenuButtonText}>{getInitials(user?.team?.name)}</span>
        </div>
      </div>

      <ControlledMenu
        {...menuProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
        arrow
        arrowProps={{ className: 'border-l-gray-200 border-t-gray-200 -ml-2' }}
        align="end"
        menuClassName="gap-1 w-42 bg-white rounded-base py-1.5 shadow-lg border-solid border border-gray-200 !min-w-[192px] !ml-2"
      >
        <AccountMenu className="max-w-[238px]" />
      </ControlledMenu>
    </>
  )
}

export default UserMenu
