import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'

import Hero from '../../components/Hero'
import Article from '../../components/Article'

export interface NetsuiteProps {}

const HERO_IMAGE = '/paywalls/netsuite/hero.png'

const ARTICLES = [
  {
    title: i18n.t('paywalls:netsuite.articles.transparency.title'),
    text: i18n.t('paywalls:netsuite.articles.transparency.text'),
    image: '/paywalls/netsuite/transparency.png',
  },
  {
    title: i18n.t('paywalls:netsuite.articles.sync.title'),
    text: i18n.t('paywalls:netsuite.articles.sync.text'),
    image: '/paywalls/netsuite/sync.png',
  },
]

export const Netsuite: React.FC<NetsuiteProps> = () => {
  const { t } = useTranslation('paywalls', { keyPrefix: 'netsuite' })
  return (
    <>
      <Hero title={t('hero.title')} image={HERO_IMAGE} subtitle={t('hero.subtitle')} />
      <div className="flex flex-col gap-6">
        {ARTICLES.map((article, index) => (
          <Article
            key={article.title}
            title={article.title}
            image={article.image}
            text={article.text}
            hasActionButtons={index === ARTICLES.length - 1}
          />
        ))}
      </div>
    </>
  )
}

export default Netsuite
